/**
 * NOTE: All themes ripped from https://rainglow.io/
 * Please go support the awesome rainglow project! 
 */
const themes = {
    'absent-contrast': {name: 'Absent Contrast', dark: true, foreground: '#aeb9c4', background: '#0e1114', comment: '#44515e', string: '#addbbc', number: '#61bcc6', first: '#228a96', second: '#6BA77F', third: '#e6eaef', fourth: '#228a96', fifth: '#6BA77F'},
    'absent-light': {name: 'Absent Light', dark: false, foreground: '#465360', background: '#ffffff', comment: '#aeb9c4', string: '#478e5f', number: '#61bcc6', first: '#228a96', second: '#6BA77F', third: '#465360', fourth: '#228a96', fifth: '#6BA77F'},
    'absent': {name: 'Absent', dark: true, foreground: '#aeb9c4', background: '#252c33', comment: '#44515e', string: '#addbbc', number: '#61bcc6', first: '#228a96', second: '#6BA77F', third: '#e6eaef', fourth: '#228a96', fifth: '#6BA77F'},
    'allure-contrast': {name: 'Allure Contrast', dark: true, foreground: '#c0ccdb', background: '#17191c', comment: '#76818e', string: '#cec86f', number: '#cea36f', first: '#5DA892', second: '#E4D294', third: '#ffffff', fourth: '#5DA892', fifth: '#E4D294'},
    'allure-light': {name: 'Allure Light', dark: false, foreground: '#555e68', background: '#ffffff', comment: '#b5c0cc', string: '#cec86f', number: '#cea36f', first: '#5DA892', second: '#E4D294', third: '#555e68', fourth: '#5DA892', fifth: '#E4D294'},
    'allure': {name: 'Allure', dark: true, foreground: '#c0ccdb', background: '#32373d', comment: '#76818e', string: '#cec86f', number: '#cea36f', first: '#5DA892', second: '#E4D294', third: '#ffffff', fourth: '#5DA892', fifth: '#E4D294'},
    'arstotzka-contrast': {name: 'Arstotzka Contrast', dark: true, foreground: '#edebe6', background: '#0f0e0d', comment: '#3f3a36', string: '#a5e3d0', number: '#a5e3d0', first: '#a2a797', second: '#516b6b', third: '#70807b', fourth: '#a2a797', fifth: '#cf433e'},
    'arstotzka-light': {name: 'Arstotzka Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#bbbbbb', string: '#7ebcaa', number: '#7ebcaa', first: '#a2a797', second: '#516b6b', third: '#70807b', fourth: '#a2a797', fifth: '#cf433e'},
    'arstotzka': {name: 'Arstotzka', dark: true, foreground: '#edebe6', background: '#332e2e', comment: '#6b625b', string: '#a5e3d0', number: '#a5e3d0', first: '#a2a797', second: '#516b6b', third: '#70807b', fourth: '#a2a797', fifth: '#cf433e'},
    'azure-contrast': {name: 'Azure Contrast', dark: true, foreground: '#ffffff', background: '#040507', comment: '#414d62', string: '#64aeb3', number: '#64aeb3', first: '#6ab0a3', second: '#52708b', third: '#508aaa', fourth: '#508aaa', fifth: '#6ab0a3'},
    'azure-light': {name: 'Azure Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#64aeb3', number: '#64aeb3', first: '#6ab0a3', second: '#8291ad', third: '#508aaa', fourth: '#508aaa', fifth: '#6ab0a3'},
    'azure': {name: 'Azure', dark: true, foreground: '#ffffff', background: '#181d26', comment: '#414d62', string: '#64aeb3', number: '#64aeb3', first: '#6ab0a3', second: '#52708b', third: '#508aaa', fourth: '#508aaa', fifth: '#6ab0a3'},
    'banner-contrast': {name: 'Banner Contrast', dark: true, foreground: '#c5bedd', background: '#0b0a0f', comment: '#594b87', string: '#cded1c', number: '#cded1c', first: '#7cd827', second: '#a25cdb', third: '#ffffff', fourth: '#a6ef61', fifth: '#a6ef61'},
    'banner-light': {name: 'Banner Light', dark: false, foreground: '#373247', background: '#ffffff', comment: '#d2d0db', string: '#9db515', number: '#9db515', first: '#7cd827', second: '#a25cdb', third: '#373247', fourth: '#7cd827', fifth: '#7cd827'},
    'banner': {name: 'Banner', dark: true, foreground: '#c5bedd', background: '#1d1a26', comment: '#594b87', string: '#cded1c', number: '#cded1c', first: '#7cd827', second: '#a25cdb', third: '#ffffff', fourth: '#a6ef61', fifth: '#a6ef61'},
    'blink-contrast': {name: 'Blink Contrast', dark: true, foreground: '#c0ccdb', background: '#131719', comment: '#4e5c66', string: '#84c4ce', number: '#529ca8', first: '#5298c4', second: '#D4856A', third: '#43B5B3', fourth: '#D4856A', fifth: '#43B5B3'},
    'blink-light': {name: 'Blink Light', dark: false, foreground: '#6a7e89', background: '#ffffff', comment: '#a6b4bc', string: '#84c4ce', number: '#529ca8', first: '#5298c4', second: '#D4856A', third: '#43B5B3', fourth: '#D4856A', fifth: '#43B5B3'},
    'blink': {name: 'Blink', dark: true, foreground: '#c0ccdb', background: '#283035', comment: '#4e5c66', string: '#84c4ce', number: '#529ca8', first: '#5298c4', second: '#D4856A', third: '#43B5B3', fourth: '#D4856A', fifth: '#43B5B3'},
    'bold-contrast': {name: 'Bold Contrast', dark: true, foreground: '#ffffff', background: '#0f0d0d', comment: '#534b4b', string: '#f7a21b', number: '#f7a21b', first: '#3d8e91', second: '#f0624b', third: '#b4b7ad', fourth: '#f0624b', fifth: '#3d8e91'},
    'bold-light': {name: 'Bold Light', dark: false, foreground: '#555555', background: '#ffffff', comment: '#cccccc', string: '#f7a21b', number: '#f7a21b', first: '#3d8e91', second: '#f0624b', third: '#888c81', fourth: '#f0624b', fifth: '#3d8e91'},
    'bold': {name: 'Bold', dark: true, foreground: '#ffffff', background: '#2a2626', comment: '#534b4b', string: '#f7a21b', number: '#f7a21b', first: '#3d8e91', second: '#f0624b', third: '#b4b7ad', fourth: '#f0624b', fifth: '#3d8e91'},
    'boxuk-contrast': {name: 'Box UK Contrast', dark: true, foreground: '#ffffff', background: '#111519', comment: '#60778c', string: '#15b8ae', number: '#15b8ae', first: '#017c9d', second: '#019d76', third: '#15b8ae', fourth: '#017c9d', fifth: '#019d76'},
    'boxuk-light': {name: 'Box UK Light', dark: false, foreground: '#414f5c', background: '#ffffff', comment: '#b8b6b1', string: '#15b8ae', number: '#15b8ae', first: '#017c9d', second: '#019d76', third: '#15b8ae', fourth: '#017c9d', fifth: '#019d76'},
    'boxuk': {name: 'Box UK', dark: true, foreground: '#ffffff', background: '#2a353f', comment: '#7b98b2', string: '#15b8ae', number: '#15b8ae', first: '#017c9d', second: '#019d76', third: '#15b8ae', fourth: '#017c9d', fifth: '#019d76'},
    'brave-contrast': {name: 'Brave Contrast', dark: true, foreground: '#d6dbdb', background: '#101216', comment: '#3e3c4c', string: '#63a5a5', number: '#4eadad', first: '#bc4331', second: '#908bbc', third: '#91b9c9', fourth: '#abaab7', fifth: '#908bbc'},
    'brave-light': {name: 'Brave Light', dark: false, foreground: '#2c2d2d', background: '#f7f9f9', comment: '#ccc9e0', string: '#63a5a5', number: '#4eadad', first: '#bc4331', second: '#7873a0', third: '#6e909e', fourth: '#abaab7', fifth: '#7873a0'},
    'brave': {name: 'Brave', dark: true, foreground: '#d6dbdb', background: '#333846', comment: '#64617a', string: '#63a5a5', number: '#4eadad', first: '#bc4331', second: '#908bbc', third: '#91b9c9', fourth: '#abaab7', fifth: '#908bbc'},
    'carbonight-contrast': {name: 'Carbonight Contrast', dark: true, foreground: '#ffffff', background: '#000000', comment: '#423f3d', string: '#ffffff', number: '#b0b0b0', first: '#c4c4c4', second: '#8c8c8c', third: '#ffffff', fourth: '#eeeeee', fifth: '#ffffff'},
    'carbonight-light': {name: 'Carbonight Light', dark: false, foreground: '#2e2c2b', background: '#ffffff', comment: '#cccccc', string: '#888888', number: '#b0b0b0', first: '#c4c4c4', second: '#8c8c8c', third: '#222222', fourth: '#555555', fifth: '#222222'},
    'carbonight': {name: 'Carbonight', dark: true, foreground: '#b0b0b0', background: '#2e2c2b', comment: '#423f3d', string: '#ffffff', number: '#b0b0b0', first: '#c4c4c4', second: '#8c8c8c', third: '#ffffff', fourth: '#eeeeee', fifth: '#ffffff'},
    'chocolate-contrast': {name: 'Chocolate Contrast', dark: true, foreground: '#ffffff', background: '#150f08', comment: '#795431', string: '#f7a21b', number: '#f7a21b', first: '#8b6e46', second: '#ccb697', third: '#e2cdb0', fourth: '#b99768', fifth: '#8b6e46'},
    'chocolate-light': {name: 'Chocolate Light', dark: false, foreground: '#150f08', background: '#ffffff', comment: '#e2cdb0', string: '#f7a21b', number: '#f7a21b', first: '#5b472c', second: '#a08f76', third: '#b5a48d', fourth: '#8e744f', fifth: '#5b472c'},
    'chocolate': {name: 'Chocolate', dark: true, foreground: '#ffffff', background: '#261b0e', comment: '#8e6239', string: '#f7a21b', number: '#f7a21b', first: '#8b6e46', second: '#ccb697', third: '#e2cdb0', fourth: '#b99768', fifth: '#8b6e46'},
    'codecourse-contrast': {name: 'Codecourse Contrast', dark: true, foreground: '#b6ced8', background: '#05080a', comment: '#456b7c', string: '#cccccc', number: '#aaaaaa', first: '#1EA8FC', second: '#5DCDFD', third: '#ffffff', fourth: '#1EA8FC', fifth: '#5DCDFD'},
    'codecourse-light': {name: 'Codecourse Light', dark: false, foreground: '#547f91', background: '#ffffff', comment: '#9ecee5', string: '#aaaaaa', number: '#777777', first: '#1EA8FC', second: '#5DCDFD', third: '#547f91', fourth: '#1EA8FC', fifth: '#5DCDFD'},
    'codecourse': {name: 'Codecourse', dark: true, foreground: '#b6ced8', background: '#19272D', comment: '#456b7c', string: '#cccccc', number: '#aaaaaa', first: '#1EA8FC', second: '#5DCDFD', third: '#ffffff', fourth: '#1EA8FC', fifth: '#5DCDFD'},
    'coffee-contrast': {name: 'Coffee Contrast', dark: true, foreground: '#c4babb', background: '#0f0c0c', comment: '#423738', string: '#a58163', number: '#cc8f5d', first: '#0A9F9B', second: '#F5F3EB', third: '#E77757', fourth: '#80b2b0', fifth: '#E77757'},
    'coffee-light': {name: 'Coffee Light', dark: false, foreground: '#282122', background: '#f4f2f2', comment: '#a59d9e', string: '#a58163', number: '#cc8f5d', first: '#0A9F9B', second: '#282122', third: '#E77757', fourth: '#80b2b0', fifth: '#E77757'},
    'coffee': {name: 'Coffee', dark: true, foreground: '#c4babb', background: '#282122', comment: '#5e4e50', string: '#a58163', number: '#cc8f5d', first: '#0A9F9B', second: '#F5F3EB', third: '#E77757', fourth: '#80b2b0', fifth: '#E77757'},
    'comrade-contrast': {name: 'Comrade Contrast', dark: true, foreground: '#d6dbdb', background: '#121414', comment: '#3b4747', string: '#63a5a5', number: '#4eadad', first: '#C24E4B', second: '#9BB7A7', third: '#F9F7F1', fourth: '#9BB7A7', fifth: '#C24E4B'},
    'comrade-light': {name: 'Comrade Light', dark: false, foreground: '#343939', background: '#d6dbdb', comment: '#9ba5a5', string: '#63a5a5', number: '#4eadad', first: '#C24E4B', second: '#7f9689', third: '#343939', fourth: '#7f9689', fifth: '#C24E4B'},
    'comrade': {name: 'Comrade', dark: true, foreground: '#d6dbdb', background: '#343939', comment: '#5e7070', string: '#63a5a5', number: '#4eadad', first: '#C24E4B', second: '#9BB7A7', third: '#F9F7F1', fourth: '#9BB7A7', fifth: '#C24E4B'},
    'crackpot-contrast': {name: 'Crackpot Contrast', dark: true, foreground: '#d2c9ef', background: '#181726', comment: '#454370', string: '#d2c9ef', number: '#d2c9ef', first: '#E4B363', second: '#EF6461', third: '#908cd8', fourth: '#E4B363', fifth: '#E8E9EB'},
    'crackpot-light': {name: 'Crackpot Light', dark: false, foreground: '#3a385b', background: '#e6e3ef', comment: '#b7b4c1', string: '#d2c9ef', number: '#d2c9ef', first: '#E4B363', second: '#EF6461', third: '#908cd8', fourth: '#E4B363', fifth: '#3a385b'},
    'crackpot': {name: 'Crackpot', dark: true, foreground: '#d2c9ef', background: '#3a385b', comment: '#5a5793', string: '#d2c9ef', number: '#d2c9ef', first: '#E4B363', second: '#EF6461', third: '#908cd8', fourth: '#E4B363', fifth: '#E8E9EB'},
    'crisp-contrast': {name: 'Crisp Contrast', dark: true, foreground: '#ffffff', background: '#0c090c', comment: '#574457', string: '#fc9a0f', number: '#fc9a0f', first: '#cba0cd', second: '#765478', third: '#fc6a0f', fourth: '#fc6a0f', fifth: '#cba0cd'},
    'crisp-light': {name: 'Crisp Light', dark: false, foreground: '#221a22', background: '#ffffff', comment: '#c6a7c6', string: '#fc9a0f', number: '#fc9a0f', first: '#99769b', second: '#765478', third: '#fc6a0f', fourth: '#fc6a0f', fifth: '#99769b'},
    'crisp': {name: 'Crisp', dark: true, foreground: '#ffffff', background: '#221a22', comment: '#574457', string: '#fc9a0f', number: '#fc9a0f', first: '#cba0cd', second: '#765478', third: '#fc6a0f', fourth: '#fc6a0f', fifth: '#cba0cd'},
    'dare-contrast': {name: 'Dare Contrast', dark: true, foreground: '#c0ccdb', background: '#17191c', comment: '#76818e', string: '#A5BAB3', number: '#A5BAB3', first: '#3A8881', second: '#d15736', third: '#ffffff', fourth: '#3A8881', fifth: '#d15736'},
    'dare-light': {name: 'Dare Light', dark: false, foreground: '#6e7a87', background: '#ffffff', comment: '#b3bcc6', string: '#A5BAB3', number: '#A5BAB3', first: '#3A8881', second: '#d15736', third: '#6e7a87', fourth: '#3A8881', fifth: '#d15736'},
    'dare': {name: 'Dare', dark: true, foreground: '#c0ccdb', background: '#32373d', comment: '#76818e', string: '#A5BAB3', number: '#A5BAB3', first: '#3A8881', second: '#d15736', third: '#ffffff', fourth: '#3A8881', fifth: '#d15736'},
    'darkside-contrast': {name: 'Darkside Contrast', dark: true, foreground: '#bababa', background: '#000000', comment: '#494b4d', string: '#f2d42c', number: '#8e69c9', first: '#1cc3e8', second: '#e8341c', third: '#68c244', fourth: '#f08d24', fifth: '#8e69c9'},
    'darkside-light': {name: 'Darkside Light', dark: false, foreground: '#222324', background: '#e0e0e0', comment: '#494b4d', string: '#4b8e30', number: '#7b5baf', first: '#15a2c1', second: '#bf2713', third: '#4b8e30', fourth: '#c1721d', fifth: '#7b5baf'},
    'darkside': {name: 'Darkside', dark: true, foreground: '#bababa', background: '#222324', comment: '#494b4d', string: '#f2d42c', number: '#8e69c9', first: '#1cc3e8', second: '#e8341c', third: '#68c244', fourth: '#f08d24', fifth: '#8e69c9'},
    'downpour-contrast': {name: 'Downpour Contrast', dark: true, foreground: '#d6dbdb', background: '#121419', comment: '#3f3c4c', string: '#63a5a5', number: '#4eadad', first: '#bc4331', second: '#908bbc', third: '#91b9c9', fourth: '#abaab7', fifth: '#908bbc'},
    'downpour-light': {name: 'Downpour Light', dark: false, foreground: '#2c323d', background: '#d6dbdb', comment: '#9da5a5', string: '#63a5a5', number: '#4eadad', first: '#bc4331', second: '#736f99', third: '#698996', fourth: '#787782', fifth: '#736f99'},
    'downpour': {name: 'Downpour', dark: true, foreground: '#d6dbdb', background: '#2c323d', comment: '#625e77', string: '#63a5a5', number: '#4eadad', first: '#bc4331', second: '#908bbc', third: '#91b9c9', fourth: '#abaab7', fifth: '#908bbc'},
    'earthsong-contrast': {name: 'Earthsong Contrast', dark: true, foreground: '#ebd1b7', background: '#12100f', comment: '#7a7267', string: '#f8bb39', number: '#f8bb39', first: '#95cc5e', second: '#db784d', third: '#60a365', fourth: '#db784d', fifth: '#95cc5e'},
    'earthsong-light': {name: 'Earthsong Light', dark: false, foreground: '#4d463e', background: '#ffffff', comment: '#d6cab9', string: '#f8bb39', number: '#f8bb39', first: '#95cc5e', second: '#db784d', third: '#60a365', fourth: '#db784d', fifth: '#95cc5e'},
    'earthsong': {name: 'Earthsong', dark: true, foreground: '#ebd1b7', background: '#36312c', comment: '#7a7267', string: '#f8bb39', number: '#f8bb39', first: '#95cc5e', second: '#db784d', third: '#60a365', fourth: '#db784d', fifth: '#95cc5e'},
    'fodder-contrast': {name: 'Fodder Contrast', dark: true, foreground: '#cce0d6', background: '#131c17', comment: '#456655', string: '#f7a21b', number: '#f7a21b', first: '#ad9895', second: '#98D046', third: '#FCFCFA', fourth: '#98D046', fifth: '#ad9895'},
    'fodder-light': {name: 'Fodder Light', dark: false, foreground: '#2D4137', background: '#cce0d6', comment: '#98aaa1', string: '#f7a21b', number: '#f7a21b', first: '#685b59', second: '#688e2f', third: '#2D4137', fourth: '#688e2f', fifth: '#685b59'},
    'fodder': {name: 'Fodder', dark: true, foreground: '#cce0d6', background: '#2D4137', comment: '#5f8c75', string: '#f7a21b', number: '#f7a21b', first: '#ad9895', second: '#98D046', third: '#FCFCFA', fourth: '#98D046', fifth: '#ad9895'},
    'frantic-contrast': {name: 'Frantic Contrast', dark: true, foreground: '#aeb9c4', background: '#0e1114', comment: '#44515e', string: '#afa9e5', number: '#968cea', first: '#EF6462', second: '#89A7BC', third: '#e6eaef', fourth: '#EF6462', fifth: '#89A7BC'},
    'frantic-light': {name: 'Frantic Light', dark: false, foreground: '#516172', background: '#ffffff', comment: '#44515e', string: '#afa9e5', number: '#968cea', first: '#EF6462', second: '#89A7BC', third: '#516172', fourth: '#EF6462', fifth: '#89A7BC'},
    'frantic': {name: 'Frantic', dark: true, foreground: '#aeb9c4', background: '#252c33', comment: '#44515e', string: '#afa9e5', number: '#968cea', first: '#EF6462', second: '#89A7BC', third: '#e6eaef', fourth: '#EF6462', fifth: '#89A7BC'},
    'freshcut-contrast': {name: 'FreshCut Contrast', dark: true, foreground: '#f8f8f2', background: '#000000', comment: '#737b84', string: '#e9ee00', number: '#8fbe00', first: '#4ecdc4', second: '#00a8c6', third: '#aee239', fourth: '#c8d7e8', fifth: '#4ecdc4'},
    'freshcut-light': {name: 'FreshCut Light', dark: false, foreground: '#2f3030', background: '#ffffff', comment: '#bbbbbb', string: '#777747', number: '#8fbe00', first: '#3da09a', second: '#008299', third: '#83aa29', fourth: '#8e99a5', fifth: '#3ba099'},
    'freshcut': {name: 'FreshCut', dark: true, foreground: '#f8f8f2', background: '#2f3030', comment: '#737b84', string: '#e9ee00', number: '#8fbe00', first: '#4ecdc4', second: '#00a8c6', third: '#aee239', fourth: '#c8d7e8', fifth: '#4ecdc4'},
    'friction-contrast': {name: 'Friction Contrast', dark: true, foreground: '#7e7b99', background: '#0c0c0f', comment: '#545263', string: '#bc6b79', number: '#bcae6b', first: '#8d89a5', second: '#6AA7A7', third: '#c2bed6', fourth: '#8d89a5', fifth: '#6AA7A7'},
    'friction-light': {name: 'Friction Light', dark: false, foreground: '#38363f', background: '#ffffff', comment: '#545263', string: '#bc6b79', number: '#bcae6b', first: '#8d89a5', second: '#6AA7A7', third: '#c2bed6', fourth: '#8d89a5', fifth: '#6AA7A7'},
    'friction': {name: 'Friction', dark: true, foreground: '#7e7b99', background: '#26252d', comment: '#545263', string: '#bc6b79', number: '#bcae6b', first: '#8d89a5', second: '#6AA7A7', third: '#c2bed6', fourth: '#8d89a5', fifth: '#6AA7A7'},
    'frontier-contrast': {name: 'Frontier Contrast', dark: true, foreground: '#f8f8f2', background: '#110f0e', comment: '#7a7267', string: '#f8bb39', number: '#2ebf7e', first: '#2ebf7e', second: '#f23a3a', third: '#f8bb39', fourth: '#fc803d', fifth: '#2ebf7e'},
    'frontier-light': {name: 'Frontier Light', dark: false, foreground: '#36312c', background: '#ffffff', comment: '#cccccc', string: '#f8bb39', number: '#1a724a', first: '#1a724a', second: '#b22727', third: '#af8323', fourth: '#ba5a27', fifth: '#1a724a'},
    'frontier': {name: 'Frontier', dark: true, foreground: '#f8f8f2', background: '#36312c', comment: '#7a7267', string: '#f8bb39', number: '#2ebf7e', first: '#2ebf7e', second: '#f23a3a', third: '#f8bb39', fourth: '#fc803d', fifth: '#2ebf7e'},
    'github-contrast': {name: 'Github Contrast', dark: true, foreground: '#ffffff', background: '#111111', comment: '#555555', string: '#e53d67', number: '#e53d67', first: '#7385bc', second: '#66c4c4', third: '#e53d67', fourth: '#cccccc', fifth: '#5a6fad'},
    'github-light': {name: 'Github Light', dark: false, foreground: '#555555', background: '#ffffff', comment: '#b8b6b1', string: '#dd1144', number: '#dd1144', first: '#445588', second: '#008080', third: '#dd1144', fourth: '#555555', fifth: '#445588'},
    'github': {name: 'Github', dark: true, foreground: '#ffffff', background: '#333333', comment: '#555555', string: '#e53d67', number: '#e53d67', first: '#7385bc', second: '#66c4c4', third: '#e53d67', fourth: '#cccccc', fifth: '#5a6fad'},
    'glance-contrast': {name: 'Glance Contrast', dark: true, foreground: '#ffffff', background: '#111219', comment: '#3e425e', string: '#83ba7c', number: '#7cb8ba', first: '#EF8354', second: '#6e81a0', third: '#FFFFFF', fourth: '#EF8354', fifth: '#6e81a0'},
    'glance-light': {name: 'Glance Light', dark: false, foreground: '#2D3142', background: '#ffffff', comment: '#aba6bf', string: '#5e8759', number: '#7cb8ba', first: '#EF8354', second: '#6e81a0', third: '#2D3142', fourth: '#EF8354', fifth: '#6e81a0'},
    'glance': {name: 'Glance', dark: true, foreground: '#ffffff', background: '#2D3142', comment: '#726c87', string: '#83ba7c', number: '#7cb8ba', first: '#EF8354', second: '#6e81a0', third: '#FFFFFF', fourth: '#EF8354', fifth: '#6e81a0'},
    'gloom-contrast': {name: 'Gloom Contrast', dark: true, foreground: '#d8ebe5', background: '#0f120f', comment: '#4f6e64', string: '#bcd42a', number: '#bcd42a', first: '#26a6a6', second: '#ff5d38', third: '#bcd42a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'gloom-light': {name: 'Gloom Light', dark: false, foreground: '#2a332b', background: '#d8ebe5', comment: '#4f6e64', string: '#98ad0d', number: '#98ad0d', first: '#26a6a6', second: '#ff5d38', third: '#98ad0d', fourth: '#26a6a6', fifth: '#ff5d38'},
    'gloom': {name: 'Gloom', dark: true, foreground: '#d8ebe5', background: '#2a332b', comment: '#4f6e64', string: '#bcd42a', number: '#bcd42a', first: '#26a6a6', second: '#ff5d38', third: '#bcd42a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'glowfish-contrast': {name: 'Glowfish Contrast', dark: true, foreground: '#6ea240', background: '#090b07', comment: '#3c4e2d', string: '#f8bb39', number: '#95cc5e', first: '#95cc5e', second: '#db784d', third: '#60a365', fourth: '#d65940', fifth: '#95cc5e'},
    'glowfish-light': {name: 'Glowfish Light', dark: false, foreground: '#191f13', background: '#e3eadc', comment: '#3c4e2d', string: '#f8bb39', number: '#95cc5e', first: '#95cc5e', second: '#db784d', third: '#60a365', fourth: '#d65940', fifth: '#95cc5e'},
    'glowfish': {name: 'Glowfish', dark: true, foreground: '#6ea240', background: '#191f13', comment: '#3c4e2d', string: '#f8bb39', number: '#95cc5e', first: '#95cc5e', second: '#db784d', third: '#60a365', fourth: '#d65940', fifth: '#95cc5e'},
    'goldfish-contrast': {name: 'Goldfish Contrast', dark: true, foreground: '#f8f8f2', background: '#0c0d0e', comment: '#505c63', string: '#f36e3a', number: '#f25619', first: '#f38630', second: '#fa6900', third: '#69d2e7', fourth: '#a7dbd8', fifth: '#f38630'},
    'goldfish-light': {name: 'Goldfish Light', dark: false, foreground: '#2e3336', background: '#ffffff', comment: '#869aa5', string: '#f36e3a', number: '#f25619', first: '#f38630', second: '#fa6900', third: '#69d2e7', fourth: '#91bfbc', fifth: '#f38630'},
    'goldfish': {name: 'Goldfish', dark: true, foreground: '#f8f8f2', background: '#2e3336', comment: '#505c63', string: '#f36e3a', number: '#f25619', first: '#f38630', second: '#fa6900', third: '#69d2e7', fourth: '#a7dbd8', fifth: '#f38630'},
    'grunge-contrast': {name: 'Grunge Contrast', dark: true, foreground: '#f8f8f2', background: '#0c0c0a', comment: '#5c634f', string: '#d1f2a5', number: '#f56991', first: '#d1f2a5', second: '#f56991', third: '#ffc48c', fourth: '#91a374', fifth: '#ffc48c'},
    'grunge-light': {name: 'Grunge Light', dark: false, foreground: '#31332c', background: '#f8f8f2', comment: '#5c634f', string: '#abc687', number: '#f56991', first: '#abc687', second: '#f56991', third: '#ffc48c', fourth: '#91a374', fifth: '#ffc48c'},
    'grunge': {name: 'Grunge', dark: true, foreground: '#f8f8f2', background: '#31332c', comment: '#5c634f', string: '#d1f2a5', number: '#f56991', first: '#d1f2a5', second: '#f56991', third: '#ffc48c', fourth: '#91a374', fifth: '#ffc48c'},
    'halflife-contrast': {name: 'Halflife Contrast', dark: true, foreground: '#cccccc', background: '#000000', comment: '#555555', string: '#f9d423', number: '#f9d423', first: '#fc913a', second: '#7d8991', third: '#f9d423', fourth: '#7d8991', fifth: '#f85931'},
    'halflife-light': {name: 'Halflife Light', dark: false, foreground: '#222222', background: '#f0f0f0', comment: '#555555', string: '#f9d423', number: '#f9d423', first: '#fc913a', second: '#7d8991', third: '#f9d423', fourth: '#7d8991', fifth: '#f85931'},
    'halflife': {name: 'Halflife', dark: true, foreground: '#cccccc', background: '#222222', comment: '#555555', string: '#f9d423', number: '#f9d423', first: '#fc913a', second: '#7d8991', third: '#f9d423', fourth: '#7d8991', fifth: '#f85931'},
    'hawaii-contrast': {name: 'Hawaii Contrast', dark: true, foreground: '#e8e1de', background: '#111110', comment: '#706864', string: '#a2d66b', number: '#88c448', first: '#edbd44', second: '#F75431', third: '#52b4d8', fourth: '#e2922f', fifth: '#92d8e8'},
    'hawaii-light': {name: 'Hawaii Light', dark: false, foreground: '#6d6764', background: '#ffffff', comment: '#c4b5ae', string: '#a2d66b', number: '#88c448', first: '#edbd44', second: '#F75431', third: '#52b4d8', fourth: '#e2922f', fifth: '#92d8e8'},
    'hawaii': {name: 'Hawaii', dark: true, foreground: '#e8e1de', background: '#333130', comment: '#706864', string: '#a2d66b', number: '#88c448', first: '#edbd44', second: '#F75431', third: '#52b4d8', fourth: '#e2922f', fifth: '#92d8e8'},
    'heroku-contrast': {name: 'Heroku Contrast', dark: true, foreground: '#c8c7d5', background: '#0b0b0f', comment: '#505067', string: '#a6fa62', number: '#a6fa62', first: '#585480', second: '#7873ae', third: '#ffffff', fourth: '#7873ae', fifth: '#a6fa62'},
    'heroku-light': {name: 'Heroku Light', dark: false, foreground: '#1b1b24', background: '#f7f7fc', comment: '#505067', string: '#6daf36', number: '#6daf36', first: '#585480', second: '#7873ae', third: '#b0add3', fourth: '#7873ae', fifth: '#6daf36'},
    'heroku': {name: 'Heroku', dark: true, foreground: '#c8c7d5', background: '#1b1b24', comment: '#505067', string: '#a6fa62', number: '#a6fa62', first: '#585480', second: '#7873ae', third: '#ffffff', fourth: '#7873ae', fifth: '#a6fa62'},
    'hive-contrast': {name: 'Hive Contrast', dark: true, foreground: '#ffffff', background: '#0f1016', comment: '#41435b', string: '#dbc583', number: '#83ba7c', first: '#7cb8ba', second: '#6e81a0', third: '#FFFFFF', fourth: '#7cb8ba', fifth: '#6e81a0'},
    'hive-light': {name: 'Hive Light', dark: false, foreground: '#2D3142', background: '#ffffff', comment: '#9b9fb2', string: '#dbc583', number: '#83ba7c', first: '#7cb8ba', second: '#6e81a0', third: '#2D3142', fourth: '#7cb8ba', fifth: '#6e81a0'},
    'hive': {name: 'Hive', dark: true, foreground: '#ffffff', background: '#2D3142', comment: '#4e556d', string: '#dbc583', number: '#83ba7c', first: '#7cb8ba', second: '#6e81a0', third: '#FFFFFF', fourth: '#7cb8ba', fifth: '#6e81a0'},
    'horizon-contrast': {name: 'Horizon Contrast', dark: true, foreground: '#edebe6', background: '#11101C', comment: '#36345A', string: '#FD8A25', number: '#E524A9', first: '#FD8A25', second: '#B133DC', third: '#FED230', fourth: '#E5194B', fifth: '#E524A9'},
    'horizon-light': {name: 'Horizon Light', dark: false, foreground: '#474466', background: '#ffffff', comment: '#736faa', string: '#FD8A25', number: '#E524A9', first: '#FD8A25', second: '#ba66d6', third: '#FED230', fourth: '#E5194B', fifth: '#E524A9'},
    'horizon': {name: 'Horizon', dark: true, foreground: '#ffffff', background: '#474466', comment: '#736faa', string: '#FD8A25', number: '#E524A9', first: '#FD8A25', second: '#ba66d6', third: '#FED230', fourth: '#E5194B', fifth: '#E524A9'},
    'hub-contrast': {name: 'Hub Contrast', dark: true, foreground: '#ffffff', background: '#191d21', comment: '#718493', string: '#9fbde0', number: '#9fbde0', first: '#e85362', second: '#5392db', third: '#5392db', fourth: '#e85362', fifth: '#9b78db'},
    'hub-light': {name: 'Hub Light', dark: false, foreground: '#24292e', background: '#ffffff', comment: '#6a737d', string: '#032f62', number: '#032f62', first: '#d73a49', second: '#005cc5', third: '#005cc5', fourth: '#d73a49', fifth: '#6f42c1'},
    'hub': {name: 'Hub', dark: true, foreground: '#ffffff', background: '#3b444c', comment: '#718493', string: '#9fbde0', number: '#9fbde0', first: '#e85362', second: '#5392db', third: '#5392db', fourth: '#e85362', fifth: '#9b78db'},
    'hyrule-contrast': {name: 'Hyrule Contrast', dark: true, foreground: '#c0d5c1', background: '#0c0c0c', comment: '#716d6a', string: '#ce830d', number: '#f5c504', first: '#f5c504', second: '#569e16', third: '#90c93f', fourth: '#90c93f', fifth: '#f5c504'},
    'hyrule-light': {name: 'Hyrule Light', dark: false, foreground: '#2d2c2b', background: '#c0d5c1', comment: '#93a594', string: '#ce830d', number: '#f5c504', first: '#b7950c', second: '#407710', third: '#68912e', fourth: '#68912e', fifth: '#b7950c'},
    'hyrule': {name: 'Hyrule', dark: true, foreground: '#c0d5c1', background: '#2d2c2b', comment: '#716d6a', string: '#ce830d', number: '#f5c504', first: '#f5c504', second: '#569e16', third: '#90c93f', fourth: '#90c93f', fifth: '#f5c504'},
    'iceberg-contrast': {name: 'Iceberg Contrast', dark: true, foreground: '#bdd6db', background: '#0b0e0e', comment: '#537178', string: '#ffffff', number: '#ffffff', first: '#59c0e3', second: '#2d8da1', third: '#ffffff', fourth: '#b1e2f2', fifth: '#ffffff'},
    'iceberg-light': {name: 'Iceberg Light', dark: false, foreground: '#323b3d', background: '#bdd6db', comment: '#7e9296', string: '#2d8da1', number: '#2d8da1', first: '#499fbc', second: '#2d8da1', third: '#323b3d', fourth: '#7ea3af', fifth: '#323b3d'},
    'iceberg': {name: 'Iceberg', dark: true, foreground: '#bdd6db', background: '#323b3d', comment: '#537178', string: '#ffffff', number: '#ffffff', first: '#59c0e3', second: '#2d8da1', third: '#ffffff', fourth: '#b1e2f2', fifth: '#ffffff'},
    'isotope-contrast': {name: 'Isotope Contrast', dark: true, foreground: '#F3EFF5', background: '#0e0f11', comment: '#7b85a0', string: '#86baba', number: '#afd1d1', first: '#72B01D', second: '#b3dd49', third: '#ffffff', fourth: '#b3dd49', fifth: '#72B01D'},
    'isotope-light': {name: 'Isotope Light', dark: false, foreground: '#454955', background: '#ffffff', comment: '#7b85a0', string: '#86baba', number: '#afd1d1', first: '#72B01D', second: '#98bc3e', third: '#454955', fourth: '#98bc3e', fifth: '#72B01D'},
    'isotope': {name: 'Isotope', dark: true, foreground: '#F3EFF5', background: '#454955', comment: '#7b85a0', string: '#86baba', number: '#afd1d1', first: '#72B01D', second: '#b3dd49', third: '#ffffff', fourth: '#b3dd49', fifth: '#72B01D'},
    'jewel-contrast': {name: 'Jewel Contrast', dark: true, foreground: '#c0ccdb', background: '#141619', comment: '#76818e', string: '#cec86f', number: '#cea36f', first: '#6299c4', second: '#c262c4', third: '#62c47c', fourth: '#6299c4', fifth: '#62c47c'},
    'jewel-light': {name: 'Jewel Light', dark: false, foreground: '#5c6470', background: '#ffffff', comment: '#a7b0bc', string: '#cec86f', number: '#cea36f', first: '#6299c4', second: '#c262c4', third: '#62c47c', fourth: '#6299c4', fifth: '#62c47c'},
    'jewel': {name: 'Jewel', dark: true, foreground: '#c0ccdb', background: '#32373d', comment: '#76818e', string: '#cec86f', number: '#cea36f', first: '#6299c4', second: '#c262c4', third: '#62c47c', fourth: '#6299c4', fifth: '#62c47c'},
    'jingle-contrast': {name: 'Jingle Contrast', dark: true, foreground: '#c0ccdb', background: '#0d1011', comment: '#4e5c66', string: '#84c4ce', number: '#e0ad5c', first: '#e06d5c', second: '#7EC579', third: '#98abb7', fourth: '#e06d5c', fifth: '#7EC579'},
    'jingle-light': {name: 'Jingle Light', dark: false, foreground: '#485860', background: '#ffffff', comment: '#aab8bf', string: '#84c4ce', number: '#e0ad5c', first: '#e06d5c', second: '#7EC579', third: '#98abb7', fourth: '#e06d5c', fifth: '#7EC579'},
    'jingle': {name: 'Jingle', dark: true, foreground: '#c0ccdb', background: '#283035', comment: '#4e5c66', string: '#84c4ce', number: '#e0ad5c', first: '#e06d5c', second: '#7EC579', third: '#98abb7', fourth: '#e06d5c', fifth: '#7EC579'},
    'joker-contrast': {name: 'Joker Contrast', dark: true, foreground: '#c0ccdb', background: '#141619', comment: '#76818e', string: '#cccccc', number: '#aaaaaa', first: '#00b27d', second: '#9a6fc4', third: '#ffffff', fourth: '#00b27d', fifth: '#9a6fc4'},
    'joker-light': {name: 'Joker Light', dark: false, foreground: '#4a5159', background: '#ffffff', comment: '#acb5bf', string: '#76818e', number: '#aaaaaa', first: '#00b27d', second: '#9a6fc4', third: '#4a5159', fourth: '#00b27d', fifth: '#9a6fc4'},
    'joker': {name: 'Joker', dark: true, foreground: '#c0ccdb', background: '#32373d', comment: '#76818e', string: '#cccccc', number: '#aaaaaa', first: '#00b27d', second: '#9a6fc4', third: '#ffffff', fourth: '#00b27d', fifth: '#9a6fc4'},
    'juicy-contrast': {name: 'Juicy Contrast', dark: true, foreground: '#e3e2e0', background: '#000000', comment: '#777777', string: '#3bc7b8', number: '#ce1836', first: '#ff4e50', second: '#3bc7b8', third: '#c3cb4c', fourth: '#edb92e', fifth: '#f85931'},
    'juicy-light': {name: 'Juicy Light', dark: false, foreground: '#333333', background: '#f0f0f0', comment: '#777777', string: '#3bc7b8', number: '#ce1836', first: '#ff4e50', second: '#3bc7b8', third: '#c3cb4c', fourth: '#edb92e', fifth: '#f85931'},
    'juicy': {name: 'Juicy', dark: true, foreground: '#e3e2e0', background: '#222222', comment: '#777777', string: '#3bc7b8', number: '#ce1836', first: '#ff4e50', second: '#3bc7b8', third: '#c3cb4c', fourth: '#edb92e', fifth: '#f85931'},
    'jumper-contrast': {name: 'Jumper Contrast', dark: true, foreground: '#8da0a0', background: '#0e1111', comment: '#394747', string: '#63a5a5', number: '#4eadad', first: '#36a595', second: '#F6DA40', third: '#db515c', fourth: '#e08e4c', fifth: '#36a595'},
    'jumper-light': {name: 'Jumper Light', dark: false, foreground: '#222a2a', background: '#ffffff', comment: '#cccccc', string: '#63a5a5', number: '#4eadad', first: '#36a595', second: '#c9b230', third: '#db515c', fourth: '#e08e4c', fifth: '#36a595'},
    'jumper': {name: 'Jumper', dark: true, foreground: '#8da0a0', background: '#222a2a', comment: '#4e6060', string: '#63a5a5', number: '#4eadad', first: '#36a595', second: '#F6DA40', third: '#db515c', fourth: '#e08e4c', fifth: '#36a595'},
    'keen-contrast': {name: 'Keen Contrast', dark: true, foreground: '#ffffff', background: '#000000', comment: '#374c60', string: '#b5db99', number: '#b5db99', first: '#6f8b94', second: '#8767b7', third: '#b5db99', fourth: '#6f8b94', fifth: '#8767b7'},
    'keen-light': {name: 'Keen Light', dark: false, foreground: '#111111', background: '#cccccc', comment: '#6b8eaf', string: '#7c9669', number: '#7c9669', first: '#6f8b94', second: '#8767b7', third: '#7c9669', fourth: '#6f8b94', fifth: '#8767b7'},
    'keen': {name: 'Keen', dark: true, foreground: '#cccccc', background: '#111111', comment: '#374c60', string: '#b5db99', number: '#b5db99', first: '#6f8b94', second: '#8767b7', third: '#b5db99', fourth: '#6f8b94', fifth: '#8767b7'},
    'kiwi-contrast': {name: 'Kiwi Contrast', dark: true, foreground: '#edebe6', background: '#0c0f0e', comment: '#354341', string: '#a1e6c1', number: '#a1e6c1', first: '#229986', second: '#95c72a', third: '#0b6d5c', fourth: '#229986', fifth: '#95c72a'},
    'kiwi-light': {name: 'Kiwi Light', dark: false, foreground: '#555555', background: '#ffffff', comment: '#cccccc', string: '#79b595', number: '#79b595', first: '#229986', second: '#95c72a', third: '#0b6d5c', fourth: '#229986', fifth: '#95c72a'},
    'kiwi': {name: 'Kiwi', dark: true, foreground: '#edebe6', background: '#2a3330', comment: '#576d6a', string: '#a1e6c1', number: '#a1e6c1', first: '#229986', second: '#95c72a', third: '#0b6d5c', fourth: '#229986', fifth: '#95c72a'},
    'laracasts-contrast': {name: 'Laracasts Contrast', dark: true, foreground: '#c0ccdb', background: '#292d33', comment: '#76818e', string: '#98d5d6', number: '#c68369', first: '#00b1b3', second: '#ef6733', third: '#ffffff', fourth: '#00b1b3', fifth: '#ef6733'},
    'laracasts-light': {name: 'Laracasts Light', dark: false, foreground: '#4d545d', background: '#ffffff', comment: '#9eacbc', string: '#98d5d6', number: '#c68369', first: '#00b1b3', second: '#ef6733', third: '#4d545d', fourth: '#00b1b3', fifth: '#ef6733'},
    'laracasts': {name: 'Laracasts', dark: true, foreground: '#c0ccdb', background: '#4d545d', comment: '#76818e', string: '#98d5d6', number: '#c68369', first: '#00b1b3', second: '#ef6733', third: '#ffffff', fourth: '#00b1b3', fifth: '#ef6733'},
    'laravel-contrast': {name: 'Laravel Contrast', dark: true, foreground: '#ffffff', background: '#000000', comment: '#615953', string: '#fdca49', number: '#fc580c', first: '#ffc48c', second: '#fc6b0a', third: '#fc580c', fourth: '#ffa927', fifth: '#ffc48c'},
    'laravel-light': {name: 'Laravel Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#fdca49', number: '#fc580c', first: '#ffc48c', second: '#fc6b0a', third: '#fc580c', fourth: '#ffa927', fifth: '#ffc48c'},
    'laravel': {name: 'Laravel', dark: true, foreground: '#dedede', background: '#2e2c2b', comment: '#615953', string: '#fdca49', number: '#fc580c', first: '#ffc48c', second: '#fc6b0a', third: '#fc580c', fourth: '#ffa927', fifth: '#ffc48c'},
    'lavender-contrast': {name: 'Lavender Contrast', dark: true, foreground: '#e0ceed', background: '#080709', comment: '#614e6e', string: '#f5b0ef', number: '#f25ae6', first: '#a29dfa', second: '#b657ff', third: '#f5b0ef', fourth: '#8e6da6', fifth: '#8e69c9'},
    'lavender-light': {name: 'Lavender Light', dark: false, foreground: '#29222e', background: '#ffffff', comment: '#9177a3', string: '#f5b0ef', number: '#f25ae6', first: '#a29dfa', second: '#b657ff', third: '#f5b0ef', fourth: '#8e6da6', fifth: '#8e69c9'},
    'lavender': {name: 'Lavender', dark: true, foreground: '#e0ceed', background: '#29222e', comment: '#614e6e', string: '#f5b0ef', number: '#f25ae6', first: '#a29dfa', second: '#b657ff', third: '#f5b0ef', fourth: '#8e6da6', fifth: '#8e69c9'},
    'legacy-contrast': {name: 'Legacy Contrast', dark: true, foreground: '#aec2e0', background: '#080a0c', comment: '#324357', string: '#ff410d', number: '#c7f026', first: '#267fb5', second: '#ffffff', third: '#ffb20d', fourth: '#748aa6', fifth: '#267fb5'},
    'legacy-light': {name: 'Legacy Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#324357', string: '#ff410d', number: '#c7f026', first: '#267fb5', second: '#222222', third: '#ffb20d', fourth: '#748aa6', fifth: '#267fb5'},
    'legacy': {name: 'Legacy', dark: true, foreground: '#aec2e0', background: '#212933', comment: '#425972', string: '#ff410d', number: '#c7f026', first: '#267fb5', second: '#ffffff', third: '#ffb20d', fourth: '#748aa6', fifth: '#267fb5'},
    'lichen-contrast': {name: 'Lichen Contrast', dark: true, foreground: '#9cadbc', background: '#090d11', comment: '#2c3f51', string: '#c5d671', number: '#e4f279', first: '#249388', second: '#9CE878', third: '#1A9D6B', fourth: '#246c82', fifth: '#1A9D6B'},
    'lichen-light': {name: 'Lichen Light', dark: false, foreground: '#414549', background: '#e6e9ed', comment: '#405b75', string: '#858e43', number: '#858e43', first: '#249388', second: '#64964c', third: '#1A9D6B', fourth: '#246c82', fifth: '#1A9D6B'},
    'lichen': {name: 'Lichen', dark: true, foreground: '#9cadbc', background: '#1A252F', comment: '#405b75', string: '#c5d671', number: '#e4f279', first: '#249388', second: '#9CE878', third: '#1A9D6B', fourth: '#246c82', fifth: '#1A9D6B'},
    'loyal-contrast': {name: 'Loyal Contrast', dark: true, foreground: '#bbb1c9', background: '#1d1b23', comment: '#655a72', string: '#e5c71b', number: '#e5c71b', first: '#3CBBB1', second: '#EE4266', third: '#b3adba', fourth: '#9484d6', fifth: '#b3adba'},
    'loyal-light': {name: 'Loyal Light', dark: false, foreground: '#413e4f', background: '#ffffff', comment: '#9d96bc', string: '#e5c71b', number: '#e5c71b', first: '#3CBBB1', second: '#EE4266', third: '#b3adba', fourth: '#9484d6', fifth: '#b3adba'},
    'loyal': {name: 'Loyal', dark: true, foreground: '#bbb1c9', background: '#413e4f', comment: '#655a72', string: '#e5c71b', number: '#e5c71b', first: '#3CBBB1', second: '#EE4266', third: '#b3adba', fourth: '#9484d6', fifth: '#b3adba'},
    'mauve-contrast': {name: 'Mauve Contrast', dark: true, foreground: '#af92b2', background: '#191119', comment: '#614263', string: '#baa353', number: '#d8b436', first: '#C89933', second: '#74526C', third: '#DBD053', fourth: '#C89933', fifth: '#DBD053'},
    'mauve-light': {name: 'Mauve Light', dark: false, foreground: '#372638', background: '#ffffff', comment: '#cc8ed1', string: '#baa353', number: '#d8b436', first: '#C89933', second: '#74526C', third: '#DBD053', fourth: '#C89933', fifth: '#DBD053'},
    'mauve': {name: 'Mauve', dark: true, foreground: '#af92b2', background: '#372638', comment: '#614263', string: '#baa353', number: '#d8b436', first: '#C89933', second: '#74526C', third: '#DBD053', fourth: '#C89933', fifth: '#DBD053'},
    'mellow-contrast': {name: 'Mellow Contrast', dark: true, foreground: '#f8f8f2', background: '#0b0a09', comment: '#7a7267', string: '#f8bb39', number: '#f8bb39', first: '#f2bc79', second: '#1f8181', third: '#f28972', fourth: '#f2bc79', fifth: '#f55d79'},
    'mellow-light': {name: 'Mellow Light', dark: false, foreground: '#555555', background: '#ffffff', comment: '#aaaaaa', string: '#f8bb39', number: '#f8bb39', first: '#f2bc79', second: '#1f8181', third: '#f28972', fourth: '#f2bc79', fifth: '#f55d79'},
    'mellow': {name: 'Mellow', dark: true, foreground: '#f8f8f2', background: '#36312c', comment: '#7a7267', string: '#f8bb39', number: '#f8bb39', first: '#f2bc79', second: '#1f8181', third: '#f28972', fourth: '#f2bc79', fifth: '#f55d79'},
    'mintchoc-contrast': {name: 'Mintchoc Contrast', dark: true, foreground: '#bababa', background: '#0c0a08', comment: '#564439', string: '#00e08c', number: '#00e08c', first: '#9d8262', second: '#008d62', third: '#00e08c', fourth: '#9d8262', fifth: '#008d62'},
    'mintchoc-light': {name: 'Mintchoc Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#b29b8d', string: '#00e08c', number: '#00e08c', first: '#9d8262', second: '#008d62', third: '#00e08c', fourth: '#9d8262', fifth: '#008d62'},
    'mintchoc': {name: 'Mintchoc', dark: true, foreground: '#bababa', background: '#2b221c', comment: '#564439', string: '#00e08c', number: '#00e08c', first: '#9d8262', second: '#008d62', third: '#00e08c', fourth: '#9d8262', fifth: '#008d62'},
    'monzo-contrast': {name: 'Monzo Contrast', dark: true, foreground: '#b0cbf4', background: '#0b1421', comment: '#2c4c7c', string: '#F63A4E', number: '#3ea7bc', first: '#98BAA6', second: '#247888', third: '#E6CE9F', fourth: '#E14D61', fifth: '#247888'},
    'monzo-light': {name: 'Monzo Light', dark: false, foreground: '#5a79a8', background: '#ffffff', comment: '#8ba2c4', string: '#F63A4E', number: '#3ea7bc', first: '#98BAA6', second: '#247888', third: '#ba9b5e', fourth: '#E14D61', fifth: '#247888'},
    'monzo': {name: 'Monzo', dark: true, foreground: '#b0cbf4', background: '#15243B', comment: '#2c4c7c', string: '#F63A4E', number: '#3ea7bc', first: '#98BAA6', second: '#247888', third: '#E6CE9F', fourth: '#E14D61', fifth: '#247888'},
    'morass-contrast': {name: 'Morass Contrast', dark: true, foreground: '#E4E3E1', background: '#1A1F1D', comment: '#414F48', string: '#f8bb39', number: '#f8bb39', first: '#FDEC5A', second: '#68875A', third: '#AFB54C', fourth: '#AFD0C4', fifth: '#677C71'},
    'morass-light': {name: 'Morass Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#8fa89b', string: '#f8bb39', number: '#f8bb39', first: '#cec040', second: '#68875A', third: '#AFB54C', fourth: '#80a396', fifth: '#677C71'},
    'morass': {name: 'Morass', dark: true, foreground: '#E4E3E1', background: '#313a36', comment: '#63776d', string: '#f8bb39', number: '#f8bb39', first: '#FDEC5A', second: '#68875A', third: '#AFB54C', fourth: '#AFD0C4', fifth: '#677C71'},
    'mud-contrast': {name: 'Mud Contrast', dark: true, foreground: '#ffffff', background: '#0d0b0b', comment: '#524343', string: '#b5db99', number: '#b5db99', first: '#f2c12f', second: '#f55239', third: '#8ee350', fourth: '#f8553c', fifth: '#eaba2c'},
    'mud-light': {name: 'Mud Light', dark: false, foreground: '#555555', background: '#ede8e8', comment: '#c3b8b7', string: '#88ad6e', number: '#88ad6e', first: '#c4a446', second: '#d16f60', third: '#88ad6e', fourth: '#d16f60', fifth: '#c4a446'},
    'mud': {name: 'Mud', dark: true, foreground: '#ffffff', background: '#403635', comment: '#c3b8b7', string: '#b5db99', number: '#b5db99', first: '#e9c865', second: '#ff9787', third: '#b5db99', fourth: '#ff9787', fifth: '#e9c865'},
    'newton-contrast': {name: 'Newton Contrast', dark: true, foreground: '#EAEBFF', background: '#121014', comment: '#3d3744', string: '#9FD356', number: '#9FD356', first: '#FA824C', second: '#FFBF69', third: '#3C91E6', fourth: '#FA824C', fifth: '#FFBF69'},
    'newton-light': {name: 'Newton Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#9FD356', number: '#9FD356', first: '#FA824C', second: '#FFBF69', third: '#3C91E6', fourth: '#FA824C', fifth: '#FFBF69'},
    'newton': {name: 'Newton', dark: true, foreground: '#EAEBFF', background: '#342E37', comment: '#5A5060', string: '#9FD356', number: '#9FD356', first: '#FA824C', second: '#FFBF69', third: '#3C91E6', fourth: '#FA824C', fifth: '#FFBF69'},
    'otakon-contrast': {name: 'Otakon Contrast', dark: true, foreground: '#f9f3f9', background: '#171417', comment: '#515166', string: '#9eb2d9', number: '#9eb2d9', first: '#b1a6ca', second: '#f6e6eb', third: '#e484b2', fourth: '#cacbdd', fifth: '#f0b9d4'},
    'otakon-light': {name: 'Otakon Light', dark: false, foreground: '#514b60', background: '#ffffff', comment: '#aaaaaa', string: '#9eb2d9', number: '#9eb2d9', first: '#b1a6ca', second: '#c6b3b9', third: '#e484b2', fourth: '#a8a9bf', fifth: '#f0b9d4'},
    'otakon': {name: 'Otakon', dark: true, foreground: '#f9f3f9', background: '#3f373f', comment: '#6a6a84', string: '#9eb2d9', number: '#9eb2d9', first: '#b1a6ca', second: '#f6e6eb', third: '#e484b2', fourth: '#cacbdd', fifth: '#f0b9d4'},
    'overflow-contrast': {name: 'Overflow Contrast', dark: true, foreground: '#aeb9c4', background: '#14181c', comment: '#44515e', string: '#4abcc9', number: '#ef8b75', first: '#FE532C', second: '#B0CA48', third: '#1f9aa8', fourth: '#FE532C', fifth: '#B0CA48'},
    'overflow-light': {name: 'Overflow Light', dark: false, foreground: '#3e4956', background: '#ffffff', comment: '#44515e', string: '#4abcc9', number: '#ef8b75', first: '#FE532C', second: '#B0CA48', third: '#1f9aa8', fourth: '#FE532C', fifth: '#B0CA48'},
    'overflow': {name: 'Overflow', dark: true, foreground: '#aeb9c4', background: '#252c33', comment: '#44515e', string: '#4abcc9', number: '#ef8b75', first: '#FE532C', second: '#B0CA48', third: '#1f9aa8', fourth: '#FE532C', fifth: '#B0CA48'},
    'pastel-contrast': {name: 'Pastel Contrast', dark: true, foreground: '#eeeeee', background: '#0f0f0f', comment: '#444444', string: '#c56c6c', number: '#c56c6c', first: '#9474a9', second: '#04c4a5', third: '#c56c6c', fourth: '#c5906c', fifth: '#e2e060'},
    'pastel-light': {name: 'Pastel Light', dark: false, foreground: '#222222', background: '#ffffff', comment: '#aaaaaa', string: '#c56c6c', number: '#c56c6c', first: '#9474a9', second: '#04c4a5', third: '#c56c6c', fourth: '#c5906c', fifth: '#e2e060'},
    'pastel': {name: 'Pastel', dark: true, foreground: '#eeeeee', background: '#222222', comment: '#444444', string: '#c56c6c', number: '#c56c6c', first: '#9474a9', second: '#04c4a5', third: '#c56c6c', fourth: '#c5906c', fifth: '#e2e060'},
    'patriot-contrast': {name: 'Patriot Contrast', dark: true, foreground: '#cad9e3', background: '#0d0e0f', comment: '#515e66', string: '#3790de', number: '#2e6fd9', first: '#de333c', second: '#2e6fd9', third: '#ffffff', fourth: '#bbbcc4', fifth: '#de333c'},
    'patriot-light': {name: 'Patriot Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#aaaaaa', string: '#3790de', number: '#2e6fd9', first: '#de333c', second: '#2e6fd9', third: '#333333', fourth: '#aaaaaa', fifth: '#de333c'},
    'patriot': {name: 'Patriot', dark: true, foreground: '#cad9e3', background: '#2d3133', comment: '#515e66', string: '#3790de', number: '#2e6fd9', first: '#de333c', second: '#2e6fd9', third: '#ffffff', fourth: '#bbbcc4', fifth: '#de333c'},
    'peacock-contrast': {name: 'Peacock Contrast', dark: true, foreground: '#ffffff', background: '#0c0c0b', comment: '#555555', string: '#bcd42a', number: '#bcd42a', first: '#26a6a6', second: '#ff5d38', third: '#bcd42a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'peacock-light': {name: 'Peacock Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#bbbbbb', string: '#bcd42a', number: '#bcd42a', first: '#26a6a6', second: '#ff5d38', third: '#bcd42a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'peacock': {name: 'Peacock', dark: true, foreground: '#ede0ce', background: '#2b2a27', comment: '#7a7267', string: '#bcd42a', number: '#bcd42a', first: '#26a6a6', second: '#ff5d38', third: '#bcd42a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'peacocks-in-space-contrast': {name: 'Peacocks In Space Contrast', dark: true, foreground: '#dee3ec', background: '#121419', comment: '#6e7a94', string: '#bcd42a', number: '#bcd42a', first: '#26a6a6', second: '#ff5d38', third: '#bcd42a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'peacocks-in-space-light': {name: 'Peacocks In Space Light', dark: false, foreground: '#2b303b', background: '#dee3ec', comment: '#6e7a94', string: '#a6b73a', number: '#a6b73a', first: '#26a6a6', second: '#ff5d38', third: '#a6b73a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'peacocks-in-space': {name: 'Peacocks In Space', dark: true, foreground: '#dee3ec', background: '#2b303b', comment: '#6e7a94', string: '#bcd42a', number: '#bcd42a', first: '#26a6a6', second: '#ff5d38', third: '#bcd42a', fourth: '#26a6a6', fifth: '#ff5d38'},
    'peel-contrast': {name: 'Peel Contrast', dark: true, foreground: '#edebe6', background: '#0c0b0a', comment: '#585146', string: '#f4d370', number: '#f4d370', first: '#94c7b6', second: '#d3643b', third: '#d6e1c7', fourth: '#94c7b6', fifth: '#d3643b'},
    'peel-light': {name: 'Peel Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#aaaaaa', string: '#f4d370', number: '#f4d370', first: '#94c7b6', second: '#d3643b', third: '#b2bca4', fourth: '#94c7b6', fifth: '#d3643b'},
    'peel': {name: 'Peel', dark: true, foreground: '#edebe6', background: '#23201c', comment: '#585146', string: '#f4d370', number: '#f4d370', first: '#94c7b6', second: '#d3643b', third: '#d6e1c7', fourth: '#94c7b6', fifth: '#d3643b'},
    'penitent-contrast': {name: 'Penitent Contrast', dark: true, foreground: '#b9cec7', background: '#101c18', comment: '#497769', string: '#eac03a', number: '#ea663a', first: '#e54334', second: '#ea983a', third: '#11a361', fourth: '#e54334', fifth: '#ea983a'},
    'penitent-light': {name: 'Penitent Light', dark: false, foreground: '#33564b', background: '#ffffff', comment: '#497769', string: '#eac03a', number: '#ea663a', first: '#e54334', second: '#ea983a', third: '#11a361', fourth: '#e54334', fifth: '#ea983a'},
    'penitent': {name: 'Penitent', dark: true, foreground: '#b9cec7', background: '#1f352e', comment: '#497769', string: '#eac03a', number: '#ea663a', first: '#e54334', second: '#ea983a', third: '#11a361', fourth: '#e54334', fifth: '#ea983a'},
    'piggy-contrast': {name: 'Piggy Contrast', dark: true, foreground: '#edebe6', background: '#1c1618', comment: '#3f3236', string: '#ff453e', number: '#ff453e', first: '#f52e62', second: '#fd6a5d', third: '#ff5d80', fourth: '#fd6a5d', fifth: '#f52e62'},
    'piggy-light': {name: 'Piggy Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#ff453e', number: '#ff453e', first: '#f52e62', second: '#fd6a5d', third: '#ff5d80', fourth: '#fd6a5d', fifth: '#f52e62'},
    'piggy': {name: 'Piggy', dark: true, foreground: '#edebe6', background: '#3d2f34', comment: '#685259', string: '#ff453e', number: '#ff453e', first: '#f52e62', second: '#fd6a5d', third: '#ff5d80', fourth: '#fd6a5d', fifth: '#f52e62'},
    'pleasure-contrast': {name: 'Pleasure Contrast', dark: true, foreground: '#c0ccdb', background: '#17191c', comment: '#76818e', string: '#5685ce', number: '#a175ba', first: '#a175ba', second: '#DD5D7A', third: '#A1CBC6', fourth: '#DD5D7A', fifth: '#A1CBC6'},
    'pleasure-light': {name: 'Pleasure Light', dark: false, foreground: '#474e56', background: '#ffffff', comment: '#9da7b2', string: '#5685ce', number: '#a175ba', first: '#a175ba', second: '#DD5D7A', third: '#A1CBC6', fourth: '#DD5D7A', fifth: '#A1CBC6'},
    'pleasure': {name: 'Pleasure', dark: true, foreground: '#c0ccdb', background: '#32373d', comment: '#76818e', string: '#5685ce', number: '#a175ba', first: '#a175ba', second: '#DD5D7A', third: '#A1CBC6', fourth: '#DD5D7A', fifth: '#A1CBC6'},
    'potpourri-contrast': {name: 'Potpourri Contrast', dark: true, foreground: '#f8f8f2', background: '#0d0c0c', comment: '#696363', string: '#b866fa', number: '#c84ff0', first: '#c491c4', second: '#ed1153', third: '#b866fa', fourth: '#ed1153', fifth: '#f76acb'},
    'potpourri-light': {name: 'Potpourri Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#bbbbbb', string: '#b866fa', number: '#c84ff0', first: '#c491c4', second: '#ed1153', third: '#b866fa', fourth: '#ed1153', fifth: '#f76acb'},
    'potpourri': {name: 'Potpourri', dark: true, foreground: '#f8f8f2', background: '#2e2b2c', comment: '#696363', string: '#b866fa', number: '#c84ff0', first: '#c491c4', second: '#ed1153', third: '#b866fa', fourth: '#ed1153', fifth: '#f76acb'},
    'prime-contrast': {name: 'Prime Contrast', dark: true, foreground: '#9090b2', background: '#0b0b11', comment: '#4c4c70', string: '#a2d66b', number: '#88c448', first: '#9d50ba', second: '#EE4266', third: '#FFD23F', fourth: '#F3FCF0', fifth: '#EE4266'},
    'prime-light': {name: 'Prime Light', dark: false, foreground: '#3d3d5b', background: '#ffffff', comment: '#a7a7e2', string: '#a2d66b', number: '#88c448', first: '#9d50ba', second: '#EE4266', third: '#FFD23F', fourth: '#727c6f', fifth: '#EE4266'},
    'prime': {name: 'Prime', dark: true, foreground: '#9090b2', background: '#202030', comment: '#4c4c70', string: '#a2d66b', number: '#88c448', first: '#9d50ba', second: '#EE4266', third: '#FFD23F', fourth: '#F3FCF0', fifth: '#EE4266'},
    'rainbow-contrast': {name: 'Rainbow Contrast', dark: true, foreground: '#c7d0d9', background: '#16181a', comment: '#424c55', string: '#c78feb', number: '#c78feb', first: '#b3cc57', second: '#ef746f', third: '#ffbe40', fourth: '#3fb4c5', fifth: '#f86f50'},
    'rainbow-light': {name: 'Rainbow Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#c78feb', number: '#c78feb', first: '#b3cc57', second: '#ef746f', third: '#ffbe40', fourth: '#3fb4c5', fifth: '#f86f50'},
    'rainbow': {name: 'Rainbow', dark: true, foreground: '#c7d0d9', background: '#373c42', comment: '#657482', string: '#c78feb', number: '#c78feb', first: '#b3cc57', second: '#ef746f', third: '#ffbe40', fourth: '#3fb4c5', fifth: '#f86f50'},
    'rebellion-contrast': {name: 'Rebellion Contrast', dark: true, foreground: '#d6c7ab', background: '#0c0c09', comment: '#51513d', string: '#fdb825', number: '#fd9825', first: '#FE5F00', second: '#988F2A', third: '#8e7547', fourth: '#FE5F00', fifth: '#988F2A'},
    'rebellion-light': {name: 'Rebellion Light', dark: false, foreground: '#443a27', background: '#ede8de', comment: '#8e8e70', string: '#fdb825', number: '#fd9825', first: '#FE5F00', second: '#988F2A', third: '#8e7547', fourth: '#FE5F00', fifth: '#988F2A'},
    'rebellion': {name: 'Rebellion', dark: true, foreground: '#d6c7ab', background: '#23231A', comment: '#51513d', string: '#fdb825', number: '#fd9825', first: '#FE5F00', second: '#988F2A', third: '#8e7547', fourth: '#FE5F00', fifth: '#988F2A'},
    'revelation-contrast': {name: 'Revelation Contrast', dark: true, foreground: '#dedede', background: '#0c0b0b', comment: '#7b726b', string: '#ffbb29', number: '#617fa0', first: '#4e5d84', second: '#617fa0', third: '#95c2e8', fourth: '#c2dcf2', fifth: '#ffbb29'},
    'revelation-light': {name: 'Revelation Light', dark: false, foreground: '#555555', background: '#ffffff', comment: '#aaaaaa', string: '#ffbb29', number: '#617fa0', first: '#4e5d84', second: '#617fa0', third: '#95c2e8', fourth: '#91abc1', fifth: '#ffbb29'},
    'revelation': {name: 'Revelation', dark: true, foreground: '#dedede', background: '#2e2c2b', comment: '#7b726b', string: '#ffbb29', number: '#617fa0', first: '#4e5d84', second: '#617fa0', third: '#95c2e8', fourth: '#c2dcf2', fifth: '#ffbb29'},
    'scorch-contrast': {name: 'Scorch Contrast', dark: true, foreground: '#baa0a2', background: '#160d0e', comment: '#44272a', string: '#a58163', number: '#cc8f5d', first: '#D39452', second: '#BD4549', third: '#CF9E51', fourth: '#F6EA82', fifth: '#E77757'},
    'scorch-light': {name: 'Scorch Light', dark: false, foreground: '#514242', background: '#f2eded', comment: '#63383c', string: '#a58163', number: '#cc8f5d', first: '#D39452', second: '#BD4549', third: '#CF9E51', fourth: '#c6bb57', fifth: '#E77757'},
    'scorch': {name: 'Scorch', dark: true, foreground: '#baa0a2', background: '#392123', comment: '#63383c', string: '#a58163', number: '#cc8f5d', first: '#D39452', second: '#BD4549', third: '#CF9E51', fourth: '#F6EA82', fifth: '#E77757'},
    'service-contrast': {name: 'Service Contrast', dark: true, foreground: '#e8e1de', background: '#0c0c0c', comment: '#4b5656', string: '#a2d66b', number: '#88c448', first: '#6C8375', second: '#A0AFA1', third: '#F0F5F5', fourth: '#A0AFA1', fifth: '#6C8375'},
    'service-light': {name: 'Service Light', dark: false, foreground: '#4d5151', background: '#ffffff', comment: '#b1c9c9', string: '#a2d66b', number: '#88c448', first: '#6C8375', second: '#A0AFA1', third: '#8c9191', fourth: '#A0AFA1', fifth: '#6C8375'},
    'service': {name: 'Service', dark: true, foreground: '#e8e1de', background: '#252727', comment: '#4b5656', string: '#a2d66b', number: '#88c448', first: '#6C8375', second: '#A0AFA1', third: '#F0F5F5', fourth: '#A0AFA1', fifth: '#6C8375'},
    'shrek-contrast': {name: 'Shrek Contrast', dark: true, foreground: '#ffffff', background: '#000000', comment: '#555555', string: '#81e911', number: '#81e911', first: '#b2de62', second: '#857a5e', third: '#f0f2eb', fourth: '#bfb59b', fifth: '#b2de62'},
    'shrek-light': {name: 'Shrek Light', dark: false, foreground: '#222222', background: '#ffffff', comment: '#aaaaaa', string: '#81e911', number: '#81e911', first: '#b2de62', second: '#857a5e', third: '#afb2a7', fourth: '#bfb59b', fifth: '#b2de62'},
    'shrek': {name: 'Shrek', dark: true, foreground: '#ffffff', background: '#222222', comment: '#555555', string: '#81e911', number: '#81e911', first: '#b2de62', second: '#857a5e', third: '#f0f2eb', fourth: '#bfb59b', fifth: '#b2de62'},
    'slate-contrast': {name: 'Slate Contrast', dark: true, foreground: '#ebebf4', background: '#19191f', comment: '#515166', string: '#9eb2d9', number: '#9eb2d9', first: '#566981', second: '#89a7b1', third: '#cbdad5', fourth: '#566981', fifth: '#89a7b1'},
    'slate-light': {name: 'Slate Light', dark: false, foreground: '#19191f', background: '#ffffff', comment: '#8282a0', string: '#9eb2d9', number: '#9eb2d9', first: '#566981', second: '#89a7b1', third: '#9aada7', fourth: '#566981', fifth: '#89a7b1'},
    'slate': {name: 'Slate', dark: true, foreground: '#ebebf4', background: '#3d3d4c', comment: '#6c6c87', string: '#9eb2d9', number: '#9eb2d9', first: '#566981', second: '#89a7b1', third: '#cbdad5', fourth: '#566981', fifth: '#89a7b1'},
    'slime-contrast': {name: 'Slime Contrast', dark: true, foreground: '#ffffff', background: '#0b0c0d', comment: '#4f5a63', string: '#faffdb', number: '#c7af3f', first: '#d8e778', second: '#6a9ec5', third: '#d0b123', fourth: '#689dc5', fifth: '#dfbf29'},
    'slime-light': {name: 'Slime Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#999999', string: '#8b9355', number: '#c7af3f', first: '#8b9355', second: '#9fb3c2', third: '#c7af3f', fourth: '#9fb3c2', fifth: '#c7af3f'},
    'slime': {name: 'Slime', dark: true, foreground: '#ffffff', background: '#292d30', comment: '#4f5a63', string: '#faffdb', number: '#c7af3f', first: '#faffdb', second: '#9fb3c2', third: '#c7af3f', fourth: '#9fb3c2', fifth: '#c7af3f'},
    'snappy-contrast': {name: 'Snappy Contrast', dark: true, foreground: '#e3e2e0', background: '#0c0c0c', comment: '#696969', string: '#4ea1df', number: '#4ea1df', first: '#4ea1df', second: '#f66153', third: '#808dd3', fourth: '#f66153', fifth: '#808dd3'},
    'snappy-light': {name: 'Snappy Light', dark: false, foreground: '#555555', background: '#ffffff', comment: '#bbbbbb', string: '#4ea1df', number: '#4ea1df', first: '#4ea1df', second: '#f66153', third: '#808dd3', fourth: '#da564a', fifth: '#606aa1'},
    'snappy': {name: 'Snappy', dark: true, foreground: '#e3e2e0', background: '#393939', comment: '#696969', string: '#4ea1df', number: '#4ea1df', first: '#4ea1df', second: '#f66153', third: '#808dd3', fourth: '#f66153', fifth: '#808dd3'},
    'solarflare-contrast': {name: 'Solarflare Contrast', dark: true, foreground: '#e3e2e0', background: '#000000', comment: '#777777', string: '#ede574', number: '#ff4e50', first: '#fc913a', second: '#ff4e50', third: '#ede574', fourth: '#ff4e50', fifth: '#fc913a'},
    'solarflare-light': {name: 'Solarflare Light', dark: false, foreground: '#222222', background: '#ffffff', comment: '#999999', string: '#c6bb15', number: '#ff4e50', first: '#d87627', second: '#ff4e50', third: '#c6bb15', fourth: '#ff4e50', fifth: '#d87627'},
    'solarflare': {name: 'Solarflare', dark: true, foreground: '#e3e2e0', background: '#222222', comment: '#777777', string: '#ede574', number: '#ff4e50', first: '#fc913a', second: '#ff4e50', third: '#ede574', fourth: '#ff4e50', fifth: '#fc913a'},
    'soup-contrast': {name: 'Soup Contrast', dark: true, foreground: '#9090b2', background: '#17181c', comment: '#6b6e7f', string: '#baa353', number: '#d8b436', first: '#68B0AB', second: '#8FC0A9', third: '#C8D5B9', fourth: '#FAF3DD', fifth: '#8FC0A9'},
    'soup-light': {name: 'Soup Light', dark: false, foreground: '#666a7a', background: '#ffffff', comment: '#c5cae5', string: '#baa353', number: '#d8b436', first: '#68B0AB', second: '#8FC0A9', third: '#C8D5B9', fourth: '#a5a08e', fifth: '#8FC0A9'},
    'soup': {name: 'Soup', dark: true, foreground: '#9090b2', background: '#40424c', comment: '#6b6e7f', string: '#baa353', number: '#d8b436', first: '#68B0AB', second: '#8FC0A9', third: '#C8D5B9', fourth: '#FAF3DD', fifth: '#8FC0A9'},
    'sourlick-contrast': {name: 'Sourlick Contrast', dark: true, foreground: '#dedede', background: '#060606', comment: '#615953', string: '#e4ff33', number: '#fc580c', first: '#edf252', second: '#8ac27a', third: '#bdf522', fourth: '#d2eb31', fifth: '#ffbb29'},
    'sourlick-light': {name: 'Sourlick Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#888888', string: '#b7ce25', number: '#fc580c', first: '#bfc431', second: '#6ba05d', third: '#94c117', fourth: '#afc425', fifth: '#d89e20'},
    'sourlick': {name: 'Sourlick', dark: true, foreground: '#dedede', background: '#2e2c2b', comment: '#615953', string: '#e4ff33', number: '#fc580c', first: '#edf252', second: '#8ac27a', third: '#bdf522', fourth: '#d2eb31', fifth: '#ffbb29'},
    'spearmint-contrast': {name: 'Spearmint Contrast', dark: true, foreground: '#c9dbd9', background: '#1a2322', comment: '#749995', string: '#4cd7e0', number: '#199fa8', first: '#69adb5', second: '#25808a', third: '#4cd7e0', fourth: '#69adb5', fifth: '#199fa8'},
    'spearmint-light': {name: 'Spearmint Light', dark: false, foreground: '#719692', background: '#e1f0ee', comment: '#93c7c0', string: '#4cd7e0', number: '#199fa8', first: '#69adb5', second: '#25808a', third: '#4cd7e0', fourth: '#69adb5', fifth: '#199fa8'},
    'spearmint': {name: 'Spearmint', dark: true, foreground: '#c9dbd9', background: '#415654', comment: '#749995', string: '#4cd7e0', number: '#199fa8', first: '#69adb5', second: '#25808a', third: '#4cd7e0', fourth: '#69adb5', fifth: '#199fa8'},
    'spitfire-contrast': {name: 'Spitfire Contrast', dark: true, foreground: '#bad3d0', background: '#091c19', comment: '#2d7a7f', string: '#f78d30', number: '#f78d30', first: '#F75431', second: '#FACC54', third: '#8EC065', fourth: '#30985B', fifth: '#F75431'},
    'spitfire-light': {name: 'Spitfire Light', dark: false, foreground: '#24665e', background: '#ffffff', comment: '#a5b9ba', string: '#f78d30', number: '#f78d30', first: '#F75431', second: '#d6a62f', third: '#8EC065', fourth: '#30985B', fifth: '#F75431'},
    'spitfire': {name: 'Spitfire', dark: true, foreground: '#bad3d0', background: '#184540', comment: '#2d7a7f', string: '#f78d30', number: '#f78d30', first: '#F75431', second: '#FACC54', third: '#8EC065', fourth: '#30985B', fifth: '#F75431'},
    'stark-contrast': {name: 'Stark Contrast', dark: true, foreground: '#dedede', background: '#0b0a0a', comment: '#615953', string: '#ffbb29', number: '#617fa0', first: '#ffbb29', second: '#f03113', third: '#f03113', fourth: '#aaaaaa', fifth: '#ffbb29'},
    'stark-light': {name: 'Stark Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#888888', string: '#ffbb29', number: '#617fa0', first: '#ffbb29', second: '#f03113', third: '#f03113', fourth: '#666666', fifth: '#ffbb29'},
    'stark': {name: 'Stark', dark: true, foreground: '#dedede', background: '#2e2c2b', comment: '#615953', string: '#ffbb29', number: '#617fa0', first: '#ffbb29', second: '#f03113', third: '#f03113', fourth: '#aaaaaa', fifth: '#ffbb29'},
    'stasis-contrast': {name: 'Stasis Contrast', dark: true, foreground: '#bbb1c9', background: '#0e0d0f', comment: '#655a72', string: '#e5c71b', number: '#e5c71b', first: '#FC814A', second: '#7c617c', third: '#BFBFBF', fourth: '#FC814A', fifth: '#BFBFBF'},
    'stasis-light': {name: 'Stasis Light', dark: false, foreground: '#605a68', background: '#ffffff', comment: '#d0c0e2', string: '#e5c71b', number: '#e5c71b', first: '#FC814A', second: '#7c617c', third: '#777777', fourth: '#FC814A', fifth: '#777777'},
    'stasis': {name: 'Stasis', dark: true, foreground: '#bbb1c9', background: '#2f2c33', comment: '#655a72', string: '#e5c71b', number: '#e5c71b', first: '#FC814A', second: '#7c617c', third: '#BFBFBF', fourth: '#FC814A', fifth: '#BFBFBF'},
    'stealth-contrast': {name: 'Stealth Contrast', dark: true, foreground: '#5f656d', background: '#0d0e0f', comment: '#323538', string: '#545a60', number: '#545a60', first: '#3c4044', second: '#474c51', third: '#545a60', fourth: '#474c51', fifth: '#3c4044'},
    'stealth-light': {name: 'Stealth Light', dark: false, foreground: '#cccccc', background: '#ffffff', comment: '#dddddd', string: '#888888', number: '#888888', first: '#bbbbbb', second: '#dddddd', third: '#aaaaaa', fourth: '#eeeeee', fifth: '#bbbbbb'},
    'stealth': {name: 'Stealth', dark: true, foreground: '#5f656d', background: '#27292b', comment: '#323538', string: '#545a60', number: '#545a60', first: '#3c4044', second: '#474c51', third: '#545a60', fourth: '#474c51', fifth: '#3c4044'},
    'storm-contrast': {name: 'Storm Contrast', dark: true, foreground: '#bacedd', background: '#020b0c', comment: '#145059', string: '#fdb825', number: '#fd9825', first: '#126d6b', second: '#00A9A5', third: '#4E8098', fourth: '#126d6b', fifth: '#00A9A5'},
    'storm-light': {name: 'Storm Light', dark: false, foreground: '#092327', background: '#dee4e5', comment: '#145059', string: '#fdb825', number: '#fd9825', first: '#126d6b', second: '#00A9A5', third: '#4E8098', fourth: '#126d6b', fifth: '#00A9A5'},
    'storm': {name: 'Storm', dark: true, foreground: '#bacedd', background: '#092327', comment: '#145059', string: '#fdb825', number: '#fd9825', first: '#126d6b', second: '#00A9A5', third: '#4E8098', fourth: '#126d6b', fifth: '#00A9A5'},
    'super-contrast': {name: 'Super Contrast', dark: true, foreground: '#ffffff', background: '#15191d', comment: '#465360', string: '#f7a21b', number: '#f7a21b', first: '#5d67ad', second: '#d60257', third: '#e45635', fourth: '#5d67ad', fifth: '#e45635'},
    'super-light': {name: 'Super Light', dark: false, foreground: '#333333', background: '#ffffff', comment: '#999999', string: '#f7a21b', number: '#f7a21b', first: '#5d67ad', second: '#d60257', third: '#e45635', fourth: '#5d67ad', fifth: '#e45635'},
    'super': {name: 'Super', dark: true, foreground: '#ffffff', background: '#2c343d', comment: '#59697a', string: '#f7a21b', number: '#f7a21b', first: '#5d67ad', second: '#d60257', third: '#e45635', fourth: '#5d67ad', fifth: '#e45635'},
    'tame-contrast': {name: 'Tame Contrast', dark: true, foreground: '#c0ccdb', background: '#15191c', comment: '#4e5c66', string: '#84c4ce', number: '#8770a0', first: '#4571a8', second: '#707ba0', third: '#98abb7', fourth: '#4571a8', fifth: '#707ba0'},
    'tame-light': {name: 'Tame Light', dark: false, foreground: '#495760', background: '#ffffff', comment: '#4e5c66', string: '#84c4ce', number: '#8770a0', first: '#4571a8', second: '#707ba0', third: '#495760', fourth: '#4571a8', fifth: '#707ba0'},
    'tame': {name: 'Tame', dark: true, foreground: '#c0ccdb', background: '#283035', comment: '#4e5c66', string: '#84c4ce', number: '#8770a0', first: '#4571a8', second: '#707ba0', third: '#98abb7', fourth: '#4571a8', fifth: '#707ba0'},
    'tetra-contrast': {name: 'Tetra Contrast', dark: true, foreground: '#c9d7db', background: '#050f11', comment: '#3b5f68', string: '#7fc181', number: '#7fc181', first: '#148B97', second: '#FDCE7F', third: '#e8744a', fourth: '#e2585d', fifth: '#148B97'},
    'tetra-light': {name: 'Tetra Light', dark: false, foreground: '#205a68', background: '#ffffff', comment: '#b1d6e0', string: '#7fc181', number: '#7fc181', first: '#148B97', second: '#c49a52', third: '#e8744a', fourth: '#e2585d', fifth: '#148B97'},
    'tetra': {name: 'Tetra', dark: true, foreground: '#c9d7db', background: '#13363F', comment: '#3b5f68', string: '#7fc181', number: '#7fc181', first: '#148B97', second: '#FDCE7F', third: '#e8744a', fourth: '#e2585d', fifth: '#148B97'},
    'tickle-contrast': {name: 'Tickle Contrast', dark: true, foreground: '#c1c1c1', background: '#181819', comment: '#606063', string: '#86baba', number: '#afd1d1', first: '#85FFC7', second: '#40a5a5', third: '#FF8552', fourth: '#85FFC7', fifth: '#FF8552'},
    'tickle-light': {name: 'Tickle Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#86baba', number: '#afd1d1', first: '#65c699', second: '#40a5a5', third: '#FF8552', fourth: '#65c699', fifth: '#FF8552'},
    'tickle': {name: 'Tickle', dark: true, foreground: '#c1c1c1', background: '#39393A', comment: '#606063', string: '#86baba', number: '#afd1d1', first: '#85FFC7', second: '#40a5a5', third: '#FF8552', fourth: '#85FFC7', fifth: '#FF8552'},
    'tonic-contrast': {name: 'Tonic Contrast', dark: true, foreground: '#eeeeee', background: '#111314', comment: '#4a5356', string: '#b8cd44', number: '#b8cd44', first: '#75a1a4', second: '#b8cd44', third: '#fd9f59', fourth: '#ef6e44', fifth: '#b8cd44'},
    'tonic-light': {name: 'Tonic Light', dark: false, foreground: '#2a2f31', background: '#eeeeee', comment: '#aaaaaa', string: '#b8cd44', number: '#b8cd44', first: '#75a1a4', second: '#b8cd44', third: '#fd9f59', fourth: '#ef6e44', fifth: '#b8cd44'},
    'tonic': {name: 'Tonic', dark: true, foreground: '#eeeeee', background: '#2a2f31', comment: '#4a5356', string: '#b8cd44', number: '#b8cd44', first: '#75a1a4', second: '#b8cd44', third: '#fd9f59', fourth: '#ef6e44', fifth: '#b8cd44'},
    'tribal-contrast': {name: 'Tribal Contrast', dark: true, foreground: '#ffffff', background: '#19191d', comment: '#4a4a54', string: '#64aeb3', number: '#64aeb3', first: '#c43535', second: '#5f5582', third: '#e0ddeb', fourth: '#5f5582', fifth: '#8f8aa2'},
    'tribal-light': {name: 'Tribal Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#64aeb3', number: '#64aeb3', first: '#c43535', second: '#5f5582', third: '#a09caf', fourth: '#5f5582', fifth: '#8f8aa2'},
    'tribal': {name: 'Tribal', dark: true, foreground: '#ffffff', background: '#393942', comment: '#6b6b7a', string: '#64aeb3', number: '#64aeb3', first: '#c43535', second: '#5f5582', third: '#e0ddeb', fourth: '#5f5582', fifth: '#8f8aa2'},
    'tron-contrast': {name: 'Tron Contrast', dark: true, foreground: '#aec2e0', background: '#07090b', comment: '#324357', string: '#6ee2ff', number: '#6ee2ff', first: '#267fb5', second: '#ffffff', third: '#ffffff', fourth: '#748aa6', fifth: '#267fb5'},
    'tron-light': {name: 'Tron Light', dark: false, foreground: '#222222', background: '#d7e5ed', comment: '#324357', string: '#6ee2ff', number: '#6ee2ff', first: '#267fb5', second: '#89bddd', third: '#ffffff', fourth: '#748aa6', fifth: '#267fb5'},
    'tron': {name: 'Tron', dark: true, foreground: '#aec2e0', background: '#14191f', comment: '#324357', string: '#6ee2ff', number: '#6ee2ff', first: '#267fb5', second: '#ffffff', third: '#ffffff', fourth: '#748aa6', fifth: '#267fb5'},
    'turnip-contrast': {name: 'Turnip Contrast', dark: true, foreground: '#ede0ce', background: '#080809', comment: '#7a7267', string: '#e8da5e', number: '#e8da5e', first: '#92b55f', second: '#487d76', third: '#e8da5e', fourth: '#92b55f', fifth: '#487d76'},
    'turnip-light': {name: 'Turnip Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#e8da5e', number: '#e8da5e', first: '#92b55f', second: '#487d76', third: '#e8da5e', fourth: '#92b55f', fifth: '#487d76'},
    'turnip': {name: 'Turnip', dark: true, foreground: '#ede0ce', background: '#1a1b1d', comment: '#7a7267', string: '#e8da5e', number: '#e8da5e', first: '#92b55f', second: '#487d76', third: '#e8da5e', fourth: '#92b55f', fifth: '#487d76'},
    'tweed-contrast': {name: 'Tweed Contrast', dark: true, foreground: '#ffffff', background: '#1e2026', comment: '#4d5363', string: '#e7a84b', number: '#e7a84b', first: '#777f93', second: '#5E9989', third: '#CAB696', fourth: '#E2975F', fifth: '#E75B4B'},
    'tweed-light': {name: 'Tweed Light', dark: false, foreground: '#585e6d', background: '#ffffff', comment: '#c7cee0', string: '#e7a84b', number: '#e7a84b', first: '#585e6d', second: '#5E9989', third: '#CAB696', fourth: '#E2975F', fifth: '#E75B4B'},
    'tweed': {name: 'Tweed', dark: true, foreground: '#ffffff', background: '#3b3f4c', comment: '#4d5363', string: '#e7a84b', number: '#e7a84b', first: '#777f93', second: '#5E9989', third: '#CAB696', fourth: '#E2975F', fifth: '#E75B4B'},
    'userscape-contrast': {name: 'Userscape Contrast', dark: true, foreground: '#dce2e8', background: '#15181c', comment: '#728496', string: '#e3bd14', number: '#de4d3a', first: '#a8c0e0', second: '#507db7', third: '#e3bd14', fourth: '#b3becc', fifth: '#de4d3a'},
    'userscape-light': {name: 'Userscape Light', dark: false, foreground: '#879bb0', background: '#f5f8fc', comment: '#bbbbbb', string: '#e3bd14', number: '#de4d3a', first: '#a8c0e0', second: '#355b8c', third: '#e3bd14', fourth: '#808c9c', fifth: '#de4d3a'},
    'userscape': {name: 'Userscape', dark: true, foreground: '#dce2e8', background: '#323a42', comment: '#728496', string: '#e3bd14', number: '#de4d3a', first: '#a8c0e0', second: '#507db7', third: '#e3bd14', fourth: '#b3becc', fifth: '#de4d3a'},
    'vegetable-contrast': {name: 'Vegetable Contrast', dark: true, foreground: '#e2dcdd', background: '#161314', comment: '#635456', string: '#bc6b79', number: '#bcae6b', first: '#F0A56C', second: '#689D81', third: '#D8CA96', fourth: '#F0A56C', fifth: '#689D81'},
    'vegetable-light': {name: 'Vegetable Light', dark: false, foreground: '#514547', background: '#e2dcdd', comment: '#b2a2a5', string: '#bc6b79', number: '#bcae6b', first: '#F0A56C', second: '#689D81', third: '#c6a52d', fourth: '#F0A56C', fifth: '#689D81'},
    'vegetable': {name: 'Vegetable', dark: true, foreground: '#e2dcdd', background: '#2d2627', comment: '#635456', string: '#bc6b79', number: '#bcae6b', first: '#F0A56C', second: '#689D81', third: '#D8CA96', fourth: '#F0A56C', fifth: '#689D81'},
    'violaceous-contrast': {name: 'Violaceous Contrast', dark: true, foreground: '#bdb2f7', background: '#0b0a11', comment: '#594b87', string: '#b58ad1', number: '#944fc1', first: '#725AC1', second: '#8D86C9', third: '#CAC4CE', fourth: '#725AC1', fifth: '#CAC4CE'},
    'violaceous-light': {name: 'Violaceous Light', dark: false, foreground: '#444349', background: '#e5e3ef', comment: '#594b87', string: '#b58ad1', number: '#944fc1', first: '#725AC1', second: '#8D86C9', third: '#8d8593', fourth: '#725AC1', fifth: '#8d8593'},
    'violaceous': {name: 'Violaceous', dark: true, foreground: '#bdb2f7', background: '#242038', comment: '#594b87', string: '#b58ad1', number: '#944fc1', first: '#725AC1', second: '#8D86C9', third: '#CAC4CE', fourth: '#725AC1', fifth: '#CAC4CE'},
    'vision-colorblind': {name: 'Vision (colorblind) (rainglow)', dark: true, foreground: '#ffffff', background: '#000000', comment: '#555555', string: '#21C0FC', number: '#FDBA2C', first: '#FC4D1F', second: '#FDBA2C', third: '#21C0FC', fourth: '#136EFB', fifth: '#FC1264'},
    'vision-light-colorblind': {name: 'Vision Light (colorblind) (rainglow)', dark: false, foreground: '#000000', background: '#ffffff', comment: '#555555', string: '#0b6aa5', number: '#0b6aa5', first: '#81209E', second: '#1BA2F7', third: '#F87856', fourth: '#F87856', fifth: '#81209E'},
    'volatile-contrast': {name: 'Volatile Contrast', dark: true, foreground: '#c0ccdb', background: '#141619', comment: '#76818e', string: '#DAC25C', number: '#db5a51', first: '#EE9C3D', second: '#568769', third: '#ffffff', fourth: '#EE9C3D', fifth: '#568769'},
    'volatile-light': {name: 'Volatile Light', dark: false, foreground: '#474f56', background: '#ffffff', comment: '#98a3ad', string: '#DAC25C', number: '#db5a51', first: '#EE9C3D', second: '#568769', third: '#474f56', fourth: '#EE9C3D', fifth: '#568769'},
    'volatile': {name: 'Volatile', dark: true, foreground: '#c0ccdb', background: '#32373d', comment: '#76818e', string: '#DAC25C', number: '#db5a51', first: '#EE9C3D', second: '#568769', third: '#ffffff', fourth: '#EE9C3D', fifth: '#568769'},
    'warlock-contrast': {name: 'Warlock Contrast', dark: true, foreground: '#ffffff', background: '#171123', comment: '#726c87', string: '#f78362', number: '#f7a261', first: '#F46036', second: '#5B85AA', third: '#5b64a3', fourth: '#9559c6', fifth: '#F46036'},
    'warlock-light': {name: 'Warlock Light', dark: false, foreground: '#4b4060', background: '#ffffff', comment: '#aaaaaa', string: '#f78362', number: '#f7a261', first: '#F46036', second: '#7eb1dd', third: '#7c86d6', fourth: '#b67ae8', fifth: '#F46036'},
    'warlock': {name: 'Warlock', dark: true, foreground: '#ffffff', background: '#4b4060', comment: '#7c6d96', string: '#f78362', number: '#f7a261', first: '#F46036', second: '#7eb1dd', third: '#7c86d6', fourth: '#b67ae8', fifth: '#F46036'},
    'waste-contrast': {name: 'Waste Contrast', dark: true, foreground: '#b1c7cc', background: '#081011', comment: '#223a39', string: '#c5d671', number: '#e4f279', first: '#43BDB2', second: '#BBD4BE', third: '#F9D6BC', fourth: '#F7A8A5', fifth: '#43BDB2'},
    'waste-light': {name: 'Waste Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#c5d671', number: '#a9b553', first: '#43BDB2', second: '#95ad97', third: '#d3b298', fourth: '#F7A8A5', fifth: '#43BDB2'},
    'waste': {name: 'Waste', dark: true, foreground: '#b1c7cc', background: '#1b353a', comment: '#3b6361', string: '#c5d671', number: '#e4f279', first: '#43BDB2', second: '#BBD4BE', third: '#F9D6BC', fourth: '#F7A8A5', fifth: '#43BDB2'},
    'yitzchok-contrast': {name: 'Yitzchok Contrast', dark: true, foreground: '#afb7bf', background: '#101316', comment: '#49525b', string: '#eae18f', number: '#bab47c', first: '#e7be45', second: '#7a8289', third: '#e6eaef', fourth: '#e7be45', fifth: '#7a8289'},
    'yitzchok-light': {name: 'Yitzchok Light', dark: false, foreground: '#6a7684', background: '#ffffff', comment: '#aeb9c4', string: '#afa441', number: '#998d29', first: '#e7be45', second: '#7a8289', third: '#6a7684', fourth: '#e7be45', fifth: '#7a8289'},
    'yitzchok': {name: 'Yitzchok', dark: true, foreground: '#afb7bf', background: '#252c33', comment: '#49525b', string: '#eae18f', number: '#bab47c', first: '#e7be45', second: '#7a8289', third: '#e6eaef', fourth: '#e7be45', fifth: '#7a8289'},
    'yule-contrast': {name: 'Yule Contrast', dark: true, foreground: '#ede0ce', background: '#0c0c0b', comment: '#7a7267', string: '#ebb626', number: '#ebb626', first: '#39b81f', second: '#d63131', third: '#ebb626', fourth: '#39b81f', fifth: '#d63131'},
    'yule-light': {name: 'Yule Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#ebb626', number: '#ebb626', first: '#39b81f', second: '#d63131', third: '#ebb626', fourth: '#39b81f', fifth: '#d63131'},
    'yule': {name: 'Yule', dark: true, foreground: '#ede0ce', background: '#2b2a27', comment: '#7a7267', string: '#ebb626', number: '#ebb626', first: '#39b81f', second: '#d63131', third: '#ebb626', fourth: '#39b81f', fifth: '#d63131'},
    'zacks-contrast': {name: 'Zacks Contrast', dark: true, foreground: '#f0f0f0', background: '#000000', comment: '#777777', string: '#bcd42a', number: '#bcd42a', first: '#9c7ddb', second: '#ff6a38', third: '#bcd42a', fourth: '#ff6a38', fifth: '#9c7ddb'},
    'zacks-light': {name: 'Zacks Light', dark: false, foreground: '#444444', background: '#ffffff', comment: '#aaaaaa', string: '#bcd42a', number: '#bcd42a', first: '#9c7ddb', second: '#ff6a38', third: '#bcd42a', fourth: '#ff6a38', fifth: '#9c7ddb'},
    'zacks': {name: 'Zacks', dark: true, foreground: '#f0f0f0', background: '#222222', comment: '#777777', string: '#bcd42a', number: '#bcd42a', first: '#9c7ddb', second: '#ff6a38', third: '#bcd42a', fourth: '#ff6a38', fifth: '#9c7ddb'},
};

export default themes;