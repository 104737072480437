import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom';
import  { Text, ProgressBar, View, Dimensions, ScrollView, TouchableWithoutFeedback, FlatList, Image } from 'react-native'
import { connect } from 'react-redux';
import { gsap, TweenLite, Draggable, TimelineMax } from "gsap/all";
import { withTheme } from '../../themeProvider';
import { useCompare } from '../../helpers/hooks';
import { getDurationArray } from '../../helpers/audio-helper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPause, faPlay, faStepForward, faStepBackward } from '@fortawesome/free-solid-svg-icons';

import { pauseSongInPlaylist, playSongInPlaylist, playNextSongInPlaylist, playPreviousSongInPlaylist, seekSong, setNewPlaylistOrder } from '../../actions/mediaPlayer-actions'

if(!Draggable._toArray) gsap.registerPlugin(Draggable);
//gsap.registerPlugin(TweenLite);

// Changes an elements's position in array
function arrayMove(array, from, to) {
    console.log("Re-arranging array");
    let itemToMove = array[from];
    array[from] = array[to];
    array[to] = itemToMove;
}
  
// Clamps a value to a min/max
function clamp(value, a, b) {
    return value < a ? a : (value > b ? b : value);
}

var shadow1 = "0 1px 3px  0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.6)";
var shadow2 = "0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2)";
var zIndex = 1000;
var gutterStep = 7;
var rowSize = 10;

const NowPlayingListItem = connect()(({ dispatch, itemArray, item, itemColor, itemContainer, layoutInvalidated, changePosition, updateItem }) => {
    let itemRef = useRef(null);
    let [tile, setTile] = useState(item);
    useEffect(() => {
        console.log("ItemRef changed");
        item.element = itemRef;
        updateItem(tile.index, item);
    }, [itemRef])

    const [lastY, setLastY] = useState(0);
    const [sortable, setSortable] = useState(null);
    const gridHeight = itemRef.current ? itemRef.current.height : 20;

    const updateTile = (name, value) => {
        console.log(`Updating tile: ${tile.index}, ${name}:${value}`)
        setTile({
          ...item,
          [name]: value
        });
    };

    function onDragStart() {
        setLastY(this.y);
        updateTile("isDragging", true);
        updateTile("lastIndex", tile.index);
    
        TweenLite.to(ReactDOM.findDOMNode(itemRef.current), 0.2, {
          autoAlpha : 0.75,
          boxShadow : shadow2,
          scale     : 0.95,
          zIndex    : "+=1000",
          backgroundColor: "#FFF"
        });
      }

    function onDrag() {
      var t = this.target,
          elements = itemArray.map(ia => ReactDOM.findDOMNode(ia.element.current)),
          indexChange = Math.round(this.y / t.currentHeight),
          bound1 = t.currentIndex,
          bound2 = bound1 + indexChange;
      if (bound1 < bound2) { // moved down
        TweenLite.to(elements.splice(bound1+1, bound2-bound1), 0.15, { yPercent: -100 });
        TweenLite.to(elements, 0.15, { yPercent: 0 });
      } else if (bound1 === bound2) {
        elements.splice(bound1, 1);
        TweenLite.to(elements, 0.15, { yPercent: 0 });
      } else { // moved up
        TweenLite.to(elements.splice(bound2, bound1-bound2), 0.15, { yPercent: 100 });
        TweenLite.to(elements, 0.15, { yPercent: 0 });
      }
        // // Move to end of list if not in bounds
        // if (!this.hitTest(ReactDOM.findDOMNode(itemContainer.current), 0)) {
        //   updateTile("inBounds", false);
        //   changePosition(tile.index, itemArray.length - 1);
        //   return;
        // }
        // updateTile("inBounds", true);
        // for (var i = 0; i < itemArray.length; i++) {
        //   // Row to update is used for a partial layout update
        //   // Shift left/right checks if the tile is being dragged 
        //   // towards the the tile it is testing
        //   var testTile    = itemArray[i];
        //   var testTileDom = ReactDOM.findDOMNode(testTile.element.current)
        //   var rowToUpdate = testTile.index;
        //   var shiftLeft   = (this.y < lastY && tile.index > i);
        //   var shiftRight  = (this.y > lastY && tile.index < i);
        //   var validMove   = (testTile.positioned && (shiftLeft || shiftRight));
        //   if(i == tile.index && validMove)
        //   console.log(this, testTileDom, this.hitTest(testTileDom, "50%"))
         
        //   if (this.hitTest(testTileDom, "50%") && validMove) {
        //     //if(tile.index !== i) tile.index = i;
        //     changePosition(tile.index, i, rowToUpdate);
        //     break;
        //   }
        // }
    
        // setLastY(this.y);
    }

    function onRelease() {
      var t = this.target,
          max = itemArray.length - 1,
          newIndex = Math.round(this.y / t.currentHeight),
          kids = itemArray.map(ia => ReactDOM.findDOMNode(ia.element.current));
      newIndex += (newIndex < 0 ? -1 : 0) + t.currentIndex;
      if (newIndex === max) {
        t.parentNode.appendChild(t);
      } else {
        t.parentNode.insertBefore(t, kids[newIndex+1]);
      }
      TweenLite.set(kids, { yPercent: 0, overwrite: "all" });
      TweenLite.set(t, { y: 0, color: "", backgroundColor: "transparent" });
        // updateTile("isDragging", false);
        // // Move tile back to last position if released out of bounds
        // layoutInvalidated(-1, updateTile);
          
        // let tileDOM = ReactDOM.findDOMNode(itemRef.current)
    
        // TweenLite.to(tileDOM, 0.2, {
        //   autoAlpha : 1,
        //   boxShadow: shadow1,
        //   scale     : 1,
        //   x         : tileDOM.x,
        //   y         : tileDOM.y,
        //   zIndex    : ++zIndex
        // });
    }

    useEffect(() => {
        if(!itemRef.current || sortable) return;
        let s = Draggable.create(ReactDOM.findDOMNode(itemRef.current), {
            type: "y",
            autoScroll: true,
            edgeResistance: 1,
            bounds: ReactDOM.findDOMNode(itemContainer.current),
            onDragStart: onDragStart,
            onDrag: onDrag,
            onRelease: onRelease,
            
            //liveSnap: true,
            snap:{
                y: function(y) {
                  var h = this.target.currentHeight;
                  return Math.round(y / h) * h;
                }
            }
        });
        setSortable(s);
        layoutInvalidated(item.index, updateTile);
    });
    let songDuration = getDurationArray(tile.item.duration);
    return (<TouchableWithoutFeedback 
        ref={itemRef}
        onPress={() => { if(!tile.isDragging) dispatch(playSongInPlaylist(item.index)); }}
        >
        <View 
            nat
            style={[{flexDirection:'row', padding: 2}]}>
            <View style={{width: 190}}>
                <Text numberOfLines={1} style={{ color: itemColor, paddingLeft: 10 }}>{tile.item.title} - {tile.item.artist}</Text>
            </View>
            <View style={{width: 60, paddingRight: 10}} >
                <Text style={[{ color: itemColor, textAlign:'right' }]}>{songDuration[0]}:{songDuration[1]}</Text>
            </View>
        </View>
    </TouchableWithoutFeedback>);
})

const NowPlayingList = ({ playlist, theme }) => {
    const NPDragContainer = useRef(null);
    const [ tiles, setTiles ] = useState([]);

    useEffect(() => {
        let tiles = playlist.map((item, i) => { return {
            index      : i,
            y          : 0,
            element    : null,
            item       : item,
            inBounds   : true,
            isDragging : false,
            lastIndex  : null,
            positioned : true
        } });
        setTiles(tiles);
    }, [ playlist ]);

    function setTile(index, tile) {
        if(tiles && tiles[index]) {
            tiles[index] = tile;
            setTiles(tiles.slice());
        }
    }

    function layoutInvalidated(rowToUpdate, updateTile) {
        var timeline = TimelineMax ? new TimelineMax() : null;
        var partialLayout = (rowToUpdate > -1);
        console.log(rowToUpdate);
        var time   = 0.35;
        if(!partialLayout) console.log(tiles);
      
        tiles.forEach(function(tile, index) {
          if(!tile.element) return;
          var oldRow  = tile.index;
      
          // PARTIAL LAYOUT: This condition can only occur while a tile is being 
          // dragged. The purpose of this is to only swap positions within a row, 
          // which will prevent a tile from jumping to another row if a space
          // is available. Without this, a large tile in column 0 may appear 
          // to be stuck if hit by a smaller tile, and if there is space in the 
          // row above for the smaller tile. When the user stops dragging the 
          // tile, a full layout update will happen, allowing tiles to move to
          // available spaces in rows above them.
          if (partialLayout && tile.index !== rowToUpdate) {
            return;
          }
          let element = ReactDOM.findDOMNode(tile.element.current)
      
          // If the tile being dragged is in bounds, set a new
          // last index in case it goes out of bounds
          if (tile.isDragging && tile.inBounds) {
            updateTile("lastIndex", index);
          }
      
          // Don't animate the tile that is being dragged and
          // only animate the tiles that have changes
          if(!tile.isDragging) console.log(oldRow, index);
          if (!tile.isDragging) {
            let y = (oldRow-index) * gutterStep;// + (index * rowSize);
            console.log(`animating ${tile.item.title} to index ${index} | y: ${y}`);
            var duration = time;
      
            // Boost the z-index for tiles that will travel over 
            // another tile due to a row change
            if (oldRow !== index) {
              timeline.set(element, { zIndex: ++zIndex }, "reflow");
            }
            tile.index = index;
            timeline.to(element, duration, {
              y : 0,
              //onComplete : function() { updateTile("positioned", true); },
              //onStart    : function() { updateTile("positioned", false); }
            }, "reflow");

            
          }
        });
      
        // If the row count has changed, change the height of the container
        // if (row !== playlist.length) {
        //   height   = playlist.length * gutterStep + (++row * rowSize);
        //   timeline.to(ReactDOM.findDOMNode(NPDragContainer.current), 0.2, { height: height }, "reflow");
        // }
    }

    function changePosition(from, to, rowToUpdate) {
        console.log(`currently moving: ${from}; Going to: ${to}; Need to shift ${rowToUpdate}`);
        if(rowToUpdate) arrayMove(tiles, from, to);
        // tiles.forEach((tile, i) => {
        //     if(tile.index !== i) { tile.index = i; }
        // })
        //setTiles(tiles.slice());
      
        //layoutInvalidated(rowToUpdate);
    }

    let { height } = Dimensions.get('window');
    if(!tiles || tiles.length === 0) 
        return(<ScrollView />);
    return (
        <ScrollView
                    ref={NPDragContainer}
                    style={{height: height-330}}
                >
                {tiles.map((item, i) => {
                    let itemColor = item.isActive ? theme.first : theme.foreground;
                    return <NowPlayingListItem
                        itemArray={tiles}
                        index={i}
                        item={item}
                        itemColor={itemColor}
                        itemContainer={NPDragContainer}
                        layoutInvalidated={layoutInvalidated}
                        changePosition={changePosition}
                        updateItem={setTile} />
                })}
                
        </ScrollView>);
}

export default withTheme(NowPlayingList)