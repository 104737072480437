import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom';
import  { Text, ProgressBar, View, Dimensions, ScrollView, TouchableWithoutFeedback, FlatList, Image } from 'react-native'
import { connect } from 'react-redux';
import { gsap, Draggable } from "gsap/all";
import { withTheme } from '../../themeProvider';
import { useCompare } from '../../helpers/hooks';
import { getDurationArray } from '../../helpers/audio-helper';
import { getCoverArt } from '../../actions/library-actions';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPause, faPlay, faStepForward, faStepBackward } from '@fortawesome/free-solid-svg-icons';
import NowPlayingList from './nowPlayingList';

import { pauseSongInPlaylist, playSongInPlaylist, playNextSongInPlaylist, playPreviousSongInPlaylist, seekSong, setNewPlaylistOrder } from '../../actions/mediaPlayer-actions'

import styles from '../../styles/global'
import np_styles from '../../styles/nowPlaying'

gsap.registerPlugin(Draggable);

function sortByIndex(a, b) {
    console.log(a.index, b.index);
    if(a.index > b.index) return 1;
    if(a.index < b.index) return -1;
    return 0;
}

// Changes an elements's position in array
function arrayMove(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0]);
}
  
// Clamps a value to a min/max
function clamp(value, a, b) {
    return value < a ? a : (value > b ? b : value);
}

const mapStateToProps = state => ({
    nowPlaying: state.mediaPlayer
})

const NowPlayingListItem = ({ dispatch, itemArray, index, item, itemColor, itemContainer }) => {
    const NPLI = useRef(null);
    const [sortable, setSortable] = useState();
    const [isDragging, setIsDragging] = useState(false);
    const gridHeight = NPLI.current ? NPLI.current.height : 20;
    function onDrag () {
        // Calculate the current index based on element's position
        var toIndex = clamp(Math.round(this.y / gridHeight), 0, itemArray.length - 1);
        console.log(index, toIndex);
        if (toIndex !== index) {
            arrayMove(itemArray, index, toIndex);
            console.log(itemArray);
            dispatch(setNewPlaylistOrder(itemArray.slice()));
        }
    }
    useEffect(() => {
        if(!NPLI.current || sortable) return;
        let s = Draggable.create(ReactDOM.findDOMNode(NPLI.current), {
            onDrag: onDrag,
            onDragStart: () => { setIsDragging(true); },
            onRelease: () => { setIsDragging(false); },
            bounds: ReactDOM.findDOMNode(itemContainer.current),
            edgeResistance: 0.65,
            cursor: "pointer",
            type: "y",
            autoScroll: true,
            liveSnap: true,
            snap:{
                y: function(endValue) {
                    return Math.round(endValue / gridHeight) * gridHeight;
                }
            }
        });
        setSortable(s);
    });
    let songDuration = getDurationArray(item.duration);
    return (<TouchableWithoutFeedback 
        ref={NPLI}
        onPress={() => { if(!isDragging) dispatch(playSongInPlaylist(index)); }}
        onLongPress={() => {}}
        >
        <View 
            nat
            style={[{flexDirection:'row', padding: 2}]}>
            <View style={{width: 190}}>
                <Text numberOfLines={1} style={{ color: itemColor, paddingLeft: 10 }}>{item.title} - {item.artist}</Text>
            </View>
            <View style={{width: 60, paddingRight: 10}} >
                <Text style={[{ color: itemColor, textAlign:'right' }]}>{songDuration[0]}:{songDuration[1]}</Text>
            </View>
        </View>
    </TouchableWithoutFeedback>);
}

const NowPlayingSidebar = ({ dispatch, nowPlaying, theme }) => {
    const [ coverArt, setCoverArt ] = useState();
    const NPDragContainer = useRef(null);
    let albumArtHasChanged = useCompare(nowPlaying.activePlaylist ? nowPlaying.activePlaylist[nowPlaying.activePlaylistIndex].coverArt : coverArt);
    useEffect(() => {
        if(albumArtHasChanged && nowPlaying.activePlaylist && nowPlaying.activePlaylist[nowPlaying.activePlaylistIndex].coverArt)
            dispatch(getCoverArt(nowPlaying.activePlaylist[nowPlaying.activePlaylistIndex].coverArt, setCoverArt)); 
    }, [albumArtHasChanged, nowPlaying.activePlaylist, nowPlaying.activePlaylistIndex, dispatch])

    function getItemLayout(data, index) { return { length: data.length, offset: 15 * index, index }; }
    function onTouchEvent(name, ev) {
        //element is set to 150 width
        var song = nowPlaying.activePlaylist[nowPlaying.activePlaylistIndex];
        
        var percentDuration = ev.nativeEvent.locationX / 150
        var seekValue = percentDuration * song.duration;
    
        dispatch(seekSong(seekValue));
    }

    let { height } = Dimensions.get('window')
    console.log(nowPlaying.activePlaylist);
    if(!nowPlaying || nowPlaying.activePlaylist.length === 0) 
        return(<View />);
    var uri = coverArt ? coverArt.data : null;
    let headerBackgroundColor = theme.dark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.05)';
    let playlistBackgroundColor = theme.dark ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0,0,0,.03)';
    var song = nowPlaying.activePlaylist[nowPlaying.activePlaylistIndex];
    var seekProgress = (nowPlaying.songSeek / song.duration);
    return (
        <View style={[np_styles.nowPlaying, { height: height, backgroundColor: headerBackgroundColor }]}> 
            <View style={[np_styles.mediaSection, styles.centeredcontainer]} >
                <Image 
                    source={{ uri: uri}}
                    style={{height: 150, width: 150}} />
                <View style={np_styles.progressBar}
                    onStartShouldSetResponder={(ev) => true}
                    onResponderGrant={onTouchEvent.bind(this, "onResponderGrant")}
                    onResponderMove={onTouchEvent.bind(this, "onResponderMove")}
                    >
                    <ProgressBar
                        style={{borderRadius: 10}}
                        color={theme.first}
                        trackColor={theme.background}
                        progress={seekProgress}
                    />
                </View>
                <Text numberOfLines={1} style={[np_styles.songText, { color: theme.foreground }]}>{nowPlaying.activePlaylist[nowPlaying.activePlaylistIndex].title}</Text>
                <Text numberOfLines={1} style={[np_styles.artistText, { color: theme.foreground }]}>{nowPlaying.activePlaylist[nowPlaying.activePlaylistIndex].artist}</Text>
                <View style={{flexDirection: 'row', marginTop: 10}}>
                    <TouchableWithoutFeedback onPress={() => dispatch(playPreviousSongInPlaylist())}>
                        <FontAwesomeIcon icon={ faStepBackward } size={ 12 } style={{ paddingTop: 3, paddingHorizontal: 5, color: theme.foreground }} />
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => dispatch(pauseSongInPlaylist())}>
                        <FontAwesomeIcon icon={ !nowPlaying.isPlaying ? faPlay : faPause } size={ 12 } style={{ paddingTop: 3, paddingHorizontal: 5, color: theme.foreground }} />
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => dispatch(playNextSongInPlaylist())}>
                        <FontAwesomeIcon icon={ faStepForward } size={ 12 } style={{ paddingTop: 3, paddingHorizontal: 5, color: theme.foreground }} />
                    </TouchableWithoutFeedback>
                </View>
            </View>
            <View style={[np_styles.playlistSection, { backgroundColor: playlistBackgroundColor }]}>
                <View style={[{padding: 10}, styles.centeredcontainer]}>
                    <Text style={[np_styles.playlistHeader, { color: theme.first }]}>Now Playing</Text>
                </View>
                <NowPlayingList playlist={nowPlaying.activePlaylist} />
            </View>
            <View style={[np_styles.playlistTrackCount, { backgroundColor: playlistBackgroundColor }]}>
                <Text style={{ color: theme.foreground }}>{nowPlaying.activePlaylist.length} Tracks</Text>
            </View>
        </View>);
}

export default connect(mapStateToProps)(withTheme(NowPlayingSidebar))